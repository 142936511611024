
import { getNodalSupport } from '@/modules/storm/storm.service'
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { NodalSupport } from '@consteel/storm'
import { Dof } from '@consteel/storm/src/Models/Release/Dof'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'SupportPointLabel',
  computed: {
    currentMember(): NodalSupport | null {
      return getNodalSupport(this.model, this.objectGuid)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    content(): LabelContent[] {
      return [
        {
          name: 'x',
          value: this.dofToValue(this.currentMember?.release?.x),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.x))
            ? latex_units.kn_mm
            : undefined,
        },
        {
          name: 'y',
          value: this.dofToValue(this.currentMember?.release?.y),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.y))
            ? latex_units.kn_mm
            : undefined,
        },
        {
          name: 'z',
          value: this.dofToValue(this.currentMember?.release?.z),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.z))
            ? latex_units.kn_mm
            : undefined,
        },
        {
          name: 'RX',
          value: this.dofToValue(this.currentMember?.release?.rx),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.rx))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'RY',
          value: this.dofToValue(this.currentMember?.release?.ry),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.ry))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'RZ',
          value: this.dofToValue(this.currentMember?.release?.rz),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.rz))
            ? latex_units.knm_rad
            : undefined,
        },
        {
          name: 'Rw',
          value: this.dofToValue(this.currentMember?.release?.rw),
          unit: this.IsDofNumeric(this.dofToValue(this.currentMember?.release?.rw))
            ? latex_units.knm2__rad_m
            : undefined,
        },
      ]
    },
  },
  methods: {
    dofToValue(dof: Dof | undefined): string | number | undefined {
      if (!dof) return undefined

      if (dof.type === this.$t('Fix') || dof.type === this.$t('Free')) {
        return dof.type
      }

      return roundToTwoDigits(dof.value)
    },
    IsDofNumeric(dof: string | number | undefined): boolean {
      if (typeof dof === 'number') {
        return true
      }

      return false
    },
  },
})
