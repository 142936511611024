
import { getStructuralPlate } from '@/modules/storm/storm.service'
import { latex_units } from '@/services/unit.service'
import { StructuralPlate } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'StructuralPlateLabel',
  computed: {
    currentMember(): StructuralPlate | null {
      return getStructuralPlate(this.model, this.objectGuid)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    content(): LabelContent[] {
      return [
        { name: this.$t('Thickness'), value: this.currentMember?.thickness, unit: latex_units.mm },
        { name: this.$t('Material'), value: this.currentMember?.material?.name },
      ]
    },
  },
})
