import Vue from 'vue'
import { OnStockUser } from '../graphql/graphql.types'
import { OnStockTokenData } from '../onstock'

const state = Vue.observable({
  currentOnStockUser: null as null | OnStockUser,
  onStockToken: null as null | OnStockTokenData,
  loading: false,
  error: '',
})

export const setOnStockToken = (value: OnStockTokenData | null): void => {
  state.onStockToken = value
}

export const setOnStockCurrentUser = (value: OnStockUser): void => {
  state.currentOnStockUser = value
}

export const setAuthLoading = (value: boolean): void => {
  state.loading = value
}

export const setAuthError = (errorMsg: string): void => {
  state.error = errorMsg
}

export const authStore = state as Readonly<typeof state>
