import { ParameterDirections, ParameterNames } from '../enums'

export const ParameterNamesMap: Record<ParameterNames, string> = {
  HEIGHT: 'Height',
  THICKNESS: 'Thickness',
  WIDTH: 'Width',
  DIAMETER: 'Diameter',
  MATERIAL: 'Material',
}

export const ParameterDirectionsMap: Record<string, number> = {
  DOWN: ParameterDirections.Down,
  UP: ParameterDirections.Up,
  UP_AND_DOWN: ParameterDirections.UpAndDown,
}

export const DefaultTolerance = 100
