import { OnStockProject } from '@/modules/graphql/graphql.types'
import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { CreateOnStockProjectDto, UpdateOnStockProjectDto } from '../types'

export const createOnStockProjectRequest = async (
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  const dto = new CreateOnStockProjectDto(name, description)

  const response = await doRequest(
    'users/me/projects',
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: OnStockProject = <OnStockProject>responseJson.payload
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const editOnStockProjectRequest = async (
  projectId: string,
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  const dto = new UpdateOnStockProjectDto(name, description)

  const response = await doRequest(
    `users/me/projects/${projectId}`,
    'PUT',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    const result: OnStockProject = <OnStockProject>responseJson.payload
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteOnStockProjectRequest = async (id: string): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${id}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 201) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
