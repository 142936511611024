import { ModelStatusType, ModelUserData } from '../../model'

export type CompareOptions = {
  beams: Record<string, boolean>
  plates: Record<string, boolean>
  lineLoads: Record<string, boolean>
  pointLoads: Record<string, boolean>
  supportPoint: Record<string, boolean>
  uniformSurfaceLoads: Record<string, boolean>
  linearSurfaceLoads: Record<string, boolean>
}

export class HistoryItemFile {
  id = ''
  storagePath = ''
  modelFileId = ''
  extension = ''
  size = 0
  checksum = ''
  status: ModelStatusType | '' = ''
  creationDate = 0

  constructor(obj?: Record<string, unknown>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}

export class HistoryItem {
  id = ''
  userData = {} as ModelUserData
  modelId = ''
  modelName = ''
  creationDate = 0
  title = ''
  description = ''
  isRestored = false
  restoredFromId = ''
  restoredFromTitle = ''
  previewStoragePath = ''
  softwareVersion = ''
  number? = ''
  historyItemFiles = [] as HistoryItemFile[]
  previewUrl = ''
  commentCount = 0
  isUnread = false

  constructor(obj?: Record<string, unknown> | HistoryItem) {
    if (obj) {
      Object.assign(this, obj)
    }
  }
}
