/* eslint-disable */
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Byte: { input: any; output: any; }
  DateTime: { input: any; output: any; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>;
  gt?: InputMaybe<Scalars['Byte']['input']>;
  gte?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  lt?: InputMaybe<Scalars['Byte']['input']>;
  lte?: InputMaybe<Scalars['Byte']['input']>;
  neq?: InputMaybe<Scalars['Byte']['input']>;
  ngt?: InputMaybe<Scalars['Byte']['input']>;
  ngte?: InputMaybe<Scalars['Byte']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  nlt?: InputMaybe<Scalars['Byte']['input']>;
  nlte?: InputMaybe<Scalars['Byte']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum EngineerRuleCondition {
  MaterialAndSectionChangeAllowed = 'MATERIAL_AND_SECTION_CHANGE_ALLOWED',
  NoChangeAllowed = 'NO_CHANGE_ALLOWED',
  OnlyMaterialChangeAllowed = 'ONLY_MATERIAL_CHANGE_ALLOWED'
}

export type EngineerRuleConditionOperationFilterInput = {
  eq?: InputMaybe<EngineerRuleCondition>;
  in?: InputMaybe<Array<EngineerRuleCondition>>;
  neq?: InputMaybe<EngineerRuleCondition>;
  nin?: InputMaybe<Array<EngineerRuleCondition>>;
};

export type EngineerRuleFilterInput = {
  and?: InputMaybe<Array<EngineerRuleFilterInput>>;
  condition?: InputMaybe<EngineerRuleConditionOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  or?: InputMaybe<Array<EngineerRuleFilterInput>>;
  portionName?: InputMaybe<StringOperationFilterInput>;
  portionSmadsteelId?: InputMaybe<StringOperationFilterInput>;
  user?: InputMaybe<OnStockUserFilterInput>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type HistoryItemFilterInput = {
  and?: InputMaybe<Array<HistoryItemFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  modelId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<HistoryItemFilterInput>>;
  steelspaceId?: InputMaybe<StringOperationFilterInput>;
  supplyOptimization?: InputMaybe<SupplyOptimizationFilterInput>;
  supplyOptimizationId?: InputMaybe<IntOperationFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
  warehouseOptimizationId?: InputMaybe<IntOperationFilterInput>;
};

export type HistoryItemSortInput = {
  id?: InputMaybe<SortEnumType>;
  model?: InputMaybe<OnStockModelSortInput>;
  modelId?: InputMaybe<SortEnumType>;
  steelspaceId?: InputMaybe<SortEnumType>;
  supplyOptimization?: InputMaybe<SupplyOptimizationSortInput>;
  supplyOptimizationId?: InputMaybe<SortEnumType>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationSortInput>;
  warehouseOptimizationId?: InputMaybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type LTreeFilterInput = {
  and?: InputMaybe<Array<LTreeFilterInput>>;
  nLevel?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LTreeFilterInput>>;
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfApplicationUserFilterInput = {
  all?: InputMaybe<OnStockUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OnStockUserFilterInput>;
  some?: InputMaybe<OnStockUserFilterInput>;
};

export type ListFilterInputTypeOfEngineerRuleFilterInput = {
  all?: InputMaybe<EngineerRuleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EngineerRuleFilterInput>;
  some?: InputMaybe<EngineerRuleFilterInput>;
};

export type ListFilterInputTypeOfHistoryItemFilterInput = {
  all?: InputMaybe<HistoryItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<HistoryItemFilterInput>;
  some?: InputMaybe<HistoryItemFilterInput>;
};

export type ListFilterInputTypeOfManagerRuleFilterInput = {
  all?: InputMaybe<ManagerRuleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ManagerRuleFilterInput>;
  some?: InputMaybe<ManagerRuleFilterInput>;
};

export type ListFilterInputTypeOfManagerRuleParameterFilterInput = {
  all?: InputMaybe<ManagerRuleParameterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ManagerRuleParameterFilterInput>;
  some?: InputMaybe<ManagerRuleParameterFilterInput>;
};

export type ListFilterInputTypeOfMaterialFilterInput = {
  all?: InputMaybe<MaterialFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MaterialFilterInput>;
  some?: InputMaybe<MaterialFilterInput>;
};

export type ListFilterInputTypeOfModelFilterInput = {
  all?: InputMaybe<OnStockModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OnStockModelFilterInput>;
  some?: InputMaybe<OnStockModelFilterInput>;
};

export type ListFilterInputTypeOfProjectFilterInput = {
  all?: InputMaybe<OnStockProjectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OnStockProjectFilterInput>;
  some?: InputMaybe<OnStockProjectFilterInput>;
};

export type ListFilterInputTypeOfSectionFamilyFilterInput = {
  all?: InputMaybe<SectionFamilyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionFamilyFilterInput>;
  some?: InputMaybe<SectionFamilyFilterInput>;
};

export type ListFilterInputTypeOfSectionFilterInput = {
  all?: InputMaybe<SectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionFilterInput>;
  some?: InputMaybe<SectionFilterInput>;
};

export type ListFilterInputTypeOfSectionOriginFilterInput = {
  all?: InputMaybe<SectionOriginFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionOriginFilterInput>;
  some?: InputMaybe<SectionOriginFilterInput>;
};

export type ListFilterInputTypeOfSectionParameterFilterInput = {
  all?: InputMaybe<SectionParameterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionParameterFilterInput>;
  some?: InputMaybe<SectionParameterFilterInput>;
};

export type ListFilterInputTypeOfSectionParameterValueFilterInput = {
  all?: InputMaybe<SectionParameterValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionParameterValueFilterInput>;
  some?: InputMaybe<SectionParameterValueFilterInput>;
};

export type ListFilterInputTypeOfSectionTypeFilterInput = {
  all?: InputMaybe<SectionTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SectionTypeFilterInput>;
  some?: InputMaybe<SectionTypeFilterInput>;
};

export type ListFilterInputTypeOfSupplyDefaultLengthFilterInput = {
  all?: InputMaybe<SupplyDefaultLengthFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplyDefaultLengthFilterInput>;
  some?: InputMaybe<SupplyDefaultLengthFilterInput>;
};

export type ListFilterInputTypeOfSupplyLengthFilterInput = {
  all?: InputMaybe<SupplyLengthFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplyLengthFilterInput>;
  some?: InputMaybe<SupplyLengthFilterInput>;
};

export type ListFilterInputTypeOfSupplyObjectMatchFilterInput = {
  all?: InputMaybe<SupplyObjectMatchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplyObjectMatchFilterInput>;
  some?: InputMaybe<SupplyObjectMatchFilterInput>;
};

export type ListFilterInputTypeOfSupplyOptimizationFilterInput = {
  all?: InputMaybe<SupplyOptimizationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplyOptimizationFilterInput>;
  some?: InputMaybe<SupplyOptimizationFilterInput>;
};

export type ListFilterInputTypeOfSupplyOptimizationPortionFilterInput = {
  all?: InputMaybe<SupplyOptimizationPortionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplyOptimizationPortionFilterInput>;
  some?: InputMaybe<SupplyOptimizationPortionFilterInput>;
};

export type ListFilterInputTypeOfSupplySummaryItemFilterInput = {
  all?: InputMaybe<SupplySummaryItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplySummaryItemFilterInput>;
  some?: InputMaybe<SupplySummaryItemFilterInput>;
};

export type ListFilterInputTypeOfWarehouseObjectFilterInput = {
  all?: InputMaybe<WarehouseObjectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseObjectFilterInput>;
  some?: InputMaybe<WarehouseObjectFilterInput>;
};

export type ListFilterInputTypeOfWarehouseObjectMatchFilterInput = {
  all?: InputMaybe<WarehouseObjectMatchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseObjectMatchFilterInput>;
  some?: InputMaybe<WarehouseObjectMatchFilterInput>;
};

export type ListFilterInputTypeOfWarehouseOptimizationFilterInput = {
  all?: InputMaybe<WarehouseOptimizationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseOptimizationFilterInput>;
  some?: InputMaybe<WarehouseOptimizationFilterInput>;
};

export type ListFilterInputTypeOfWarehouseOptimizationPortionFilterInput = {
  all?: InputMaybe<WarehouseOptimizationPortionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseOptimizationPortionFilterInput>;
  some?: InputMaybe<WarehouseOptimizationPortionFilterInput>;
};

export type ListFilterInputTypeOfWarehouseSummaryItemBlockFilterInput = {
  all?: InputMaybe<WarehouseSummaryItemBlockFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseSummaryItemBlockFilterInput>;
  some?: InputMaybe<WarehouseSummaryItemBlockFilterInput>;
};

export type ListFilterInputTypeOfWarehouseSummaryItemFilterInput = {
  all?: InputMaybe<WarehouseSummaryItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<WarehouseSummaryItemFilterInput>;
  some?: InputMaybe<WarehouseSummaryItemFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type ManagerRuleFilterInput = {
  and?: InputMaybe<Array<ManagerRuleFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  managerRuleParameters?: InputMaybe<ListFilterInputTypeOfManagerRuleParameterFilterInput>;
  materialDirection?: InputMaybe<NeighbourDirectionOperationFilterInput>;
  materialStep?: InputMaybe<IntOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ManagerRuleFilterInput>>;
  sectionType?: InputMaybe<SectionTypeFilterInput>;
  user?: InputMaybe<OnStockUserFilterInput>;
  warehouseOptimizations?: InputMaybe<ListFilterInputTypeOfWarehouseOptimizationFilterInput>;
};

export type ManagerRuleParameterFilterInput = {
  and?: InputMaybe<Array<ManagerRuleParameterFilterInput>>;
  direction?: InputMaybe<NeighbourDirectionOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  managerRule?: InputMaybe<ManagerRuleFilterInput>;
  or?: InputMaybe<Array<ManagerRuleParameterFilterInput>>;
  sectionParameter?: InputMaybe<SectionParameterFilterInput>;
  step?: InputMaybe<IntOperationFilterInput>;
};

export enum MatchingMethod {
  FastApproximation = 'FAST_APPROXIMATION',
  SlowAccurate = 'SLOW_ACCURATE'
}

export type MatchingMethodOperationFilterInput = {
  eq?: InputMaybe<MatchingMethod>;
  in?: InputMaybe<Array<MatchingMethod>>;
  neq?: InputMaybe<MatchingMethod>;
  nin?: InputMaybe<Array<MatchingMethod>>;
};

export type MaterialBankFilterInput = {
  and?: InputMaybe<Array<MaterialBankFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  materials?: InputMaybe<ListFilterInputTypeOfMaterialFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaterialBankFilterInput>>;
};

export type MaterialBankSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type MaterialFilterInput = {
  and?: InputMaybe<Array<MaterialFilterInput>>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  fy?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  materialBank?: InputMaybe<MaterialBankFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MaterialFilterInput>>;
};

export enum NeighbourDirection {
  Down = 'DOWN',
  Up = 'UP',
  UpAndDown = 'UP_AND_DOWN'
}

export type NeighbourDirectionOperationFilterInput = {
  eq?: InputMaybe<NeighbourDirection>;
  in?: InputMaybe<Array<NeighbourDirection>>;
  neq?: InputMaybe<NeighbourDirection>;
  nin?: InputMaybe<Array<NeighbourDirection>>;
};

export type NullableOfPriorityOperationFilterInput = {
  eq?: InputMaybe<Priority>;
  in?: InputMaybe<Array<InputMaybe<Priority>>>;
  neq?: InputMaybe<Priority>;
  nin?: InputMaybe<Array<InputMaybe<Priority>>>;
};

export type NullableOfPriorityOrderOperationFilterInput = {
  eq?: InputMaybe<PriorityOrder>;
  in?: InputMaybe<Array<InputMaybe<PriorityOrder>>>;
  neq?: InputMaybe<PriorityOrder>;
  nin?: InputMaybe<Array<InputMaybe<PriorityOrder>>>;
};

export type OnStockModelFilterInput = {
  and?: InputMaybe<Array<OnStockModelFilterInput>>;
  checksum?: InputMaybe<StringOperationFilterInput>;
  engineerRules?: InputMaybe<ListFilterInputTypeOfEngineerRuleFilterInput>;
  historyItems?: InputMaybe<ListFilterInputTypeOfHistoryItemFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  importDate?: InputMaybe<DateTimeOperationFilterInput>;
  managerRules?: InputMaybe<ListFilterInputTypeOfManagerRuleFilterInput>;
  or?: InputMaybe<Array<OnStockModelFilterInput>>;
  project?: InputMaybe<OnStockProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<OnStockModelStatusOperationFilterInput>;
  steelspaceId?: InputMaybe<StringOperationFilterInput>;
  supplyDefaultLengths?: InputMaybe<ListFilterInputTypeOfSupplyDefaultLengthFilterInput>;
  supplyDefaultWaste?: InputMaybe<SupplyDefaultWasteFilterInput>;
  supplyOptimizations?: InputMaybe<ListFilterInputTypeOfSupplyOptimizationFilterInput>;
  warehouseOptimizations?: InputMaybe<ListFilterInputTypeOfWarehouseOptimizationFilterInput>;
};

export type OnStockModelSortInput = {
  checksum?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  importDate?: InputMaybe<SortEnumType>;
  project?: InputMaybe<OnStockProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  steelspaceId?: InputMaybe<SortEnumType>;
  supplyDefaultWaste?: InputMaybe<SupplyDefaultWasteSortInput>;
};

export enum OnStockModelStatus {
  Finalized = 'FINALIZED',
  WaitingForApproval = 'WAITING_FOR_APPROVAL',
  WaitingForFinalizing = 'WAITING_FOR_FINALIZING',
  WaitingForPreparation = 'WAITING_FOR_PREPARATION',
  WaitingForWarehouseOptimization = 'WAITING_FOR_WAREHOUSE_OPTIMIZATION'
}

export type OnStockModelStatusOperationFilterInput = {
  eq?: InputMaybe<OnStockModelStatus>;
  in?: InputMaybe<Array<OnStockModelStatus>>;
  neq?: InputMaybe<OnStockModelStatus>;
  nin?: InputMaybe<Array<OnStockModelStatus>>;
};

export type OnStockProjectFilterInput = {
  and?: InputMaybe<Array<OnStockProjectFilterInput>>;
  collaborators?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  models?: InputMaybe<ListFilterInputTypeOfModelFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OnStockProjectFilterInput>>;
  owner?: InputMaybe<OnStockUserFilterInput>;
  ownerId?: InputMaybe<StringOperationFilterInput>;
};

export type OnStockProjectSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<OnStockUserSortInput>;
  ownerId?: InputMaybe<SortEnumType>;
};

export type OnStockUserFilterInput = {
  accessFailedCount?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<OnStockUserFilterInput>>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lockoutEnd?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OnStockUserFilterInput>>;
  ownedProjects?: InputMaybe<ListFilterInputTypeOfProjectFilterInput>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  projects?: InputMaybe<ListFilterInputTypeOfProjectFilterInput>;
  securityStamp?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<UserStatusOperationFilterInput>;
  steelspaceId?: InputMaybe<StringOperationFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  updatedAtDate?: InputMaybe<DateTimeOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type OnStockUserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  steelspaceId?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  updatedAtDate?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export enum Priority {
  InventoryDate = 'INVENTORY_DATE',
  Length = 'LENGTH',
  Weight = 'WEIGHT'
}

export enum PriorityOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SectionBankFilterInput = {
  and?: InputMaybe<Array<SectionBankFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionBankFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionOrigins?: InputMaybe<ListFilterInputTypeOfSectionOriginFilterInput>;
};

export type SectionBankSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
};

export type SectionFamilyFilterInput = {
  and?: InputMaybe<Array<SectionFamilyFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  managerRules?: InputMaybe<ListFilterInputTypeOfManagerRuleFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionFamilyFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionOrigin?: InputMaybe<SectionOriginFilterInput>;
  sectionTypes?: InputMaybe<ListFilterInputTypeOfSectionTypeFilterInput>;
};

export type SectionFamilySortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  sectionOrigin?: InputMaybe<SectionOriginSortInput>;
};

export type SectionFilterInput = {
  and?: InputMaybe<Array<SectionFilterInput>>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedFullName?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionParameterValues?: InputMaybe<ListFilterInputTypeOfSectionParameterValueFilterInput>;
  sectionType?: InputMaybe<SectionTypeFilterInput>;
};

export type SectionOriginFilterInput = {
  and?: InputMaybe<Array<SectionOriginFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionOriginFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionBank?: InputMaybe<SectionBankFilterInput>;
  sectionFamilies?: InputMaybe<ListFilterInputTypeOfSectionFamilyFilterInput>;
};

export type SectionOriginSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  sectionBank?: InputMaybe<SectionBankSortInput>;
};

export type SectionParameterFilterInput = {
  and?: InputMaybe<Array<SectionParameterFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionParameterFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionType?: InputMaybe<SectionTypeFilterInput>;
};

export type SectionParameterSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  sectionType?: InputMaybe<SectionTypeSortInput>;
};

export type SectionParameterValueFilterInput = {
  and?: InputMaybe<Array<SectionParameterValueFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SectionParameterValueFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  section?: InputMaybe<SectionFilterInput>;
  sectionParameter?: InputMaybe<SectionParameterFilterInput>;
  value?: InputMaybe<FloatOperationFilterInput>;
};

export type SectionParameterValueSortInput = {
  id?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  section?: InputMaybe<SectionSortInput>;
  sectionParameter?: InputMaybe<SectionParameterSortInput>;
  value?: InputMaybe<SortEnumType>;
};

export type SectionSortInput = {
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedFullName?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  sectionType?: InputMaybe<SectionTypeSortInput>;
};

export type SectionTypeFilterInput = {
  and?: InputMaybe<Array<SectionTypeFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SectionTypeFilterInput>>;
  path?: InputMaybe<LTreeFilterInput>;
  sectionFamily?: InputMaybe<SectionFamilyFilterInput>;
  sectionParameters?: InputMaybe<ListFilterInputTypeOfSectionParameterFilterInput>;
  sections?: InputMaybe<ListFilterInputTypeOfSectionFilterInput>;
};

export type SectionTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  normalizedName?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  sectionFamily?: InputMaybe<SectionFamilySortInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum SummaryType {
  Material = 'MATERIAL',
  Section = 'SECTION'
}

export type SummaryTypeOperationFilterInput = {
  eq?: InputMaybe<SummaryType>;
  in?: InputMaybe<Array<SummaryType>>;
  neq?: InputMaybe<SummaryType>;
  nin?: InputMaybe<Array<SummaryType>>;
};

export type SupplyDefaultLengthFilterInput = {
  and?: InputMaybe<Array<SupplyDefaultLengthFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  length?: InputMaybe<FloatOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  or?: InputMaybe<Array<SupplyDefaultLengthFilterInput>>;
};

export type SupplyDefaultWasteFilterInput = {
  and?: InputMaybe<Array<SupplyDefaultWasteFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  modelId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SupplyDefaultWasteFilterInput>>;
  waste?: InputMaybe<IntOperationFilterInput>;
};

export type SupplyDefaultWasteSortInput = {
  id?: InputMaybe<SortEnumType>;
  model?: InputMaybe<OnStockModelSortInput>;
  modelId?: InputMaybe<SortEnumType>;
  waste?: InputMaybe<SortEnumType>;
};

export type SupplyLengthFilterInput = {
  and?: InputMaybe<Array<SupplyLengthFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  length?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SupplyLengthFilterInput>>;
  supplyObjectMatches?: InputMaybe<ListFilterInputTypeOfSupplyObjectMatchFilterInput>;
  supplySummaryItem?: InputMaybe<SupplySummaryItemFilterInput>;
  waste?: InputMaybe<IntOperationFilterInput>;
};

export type SupplyObjectMatchFilterInput = {
  and?: InputMaybe<Array<SupplyObjectMatchFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  memberSmadsteelIds?: InputMaybe<ListStringOperationFilterInput>;
  or?: InputMaybe<Array<SupplyObjectMatchFilterInput>>;
  supplyLength?: InputMaybe<SupplyLengthFilterInput>;
  supplyOptimization?: InputMaybe<SupplyOptimizationFilterInput>;
  supplyOptimizationPortion?: InputMaybe<SupplyOptimizationPortionFilterInput>;
};

export type SupplyOptimizationFilterInput = {
  and?: InputMaybe<Array<SupplyOptimizationFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  historyItem?: InputMaybe<HistoryItemFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  objectMatches?: InputMaybe<ListFilterInputTypeOfSupplyObjectMatchFilterInput>;
  optimizationPortions?: InputMaybe<ListFilterInputTypeOfSupplyOptimizationPortionFilterInput>;
  or?: InputMaybe<Array<SupplyOptimizationFilterInput>>;
  smadsteelFile?: InputMaybe<ListByteOperationFilterInput>;
  supplySummaryItems?: InputMaybe<ListFilterInputTypeOfSupplySummaryItemFilterInput>;
  tolerance?: InputMaybe<IntOperationFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
};

export type SupplyOptimizationPortionFilterInput = {
  and?: InputMaybe<Array<SupplyOptimizationPortionFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SupplyOptimizationPortionFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  portionName?: InputMaybe<StringOperationFilterInput>;
  portionSmadsteelId?: InputMaybe<StringOperationFilterInput>;
  supplyObjectMatches?: InputMaybe<ListFilterInputTypeOfSupplyObjectMatchFilterInput>;
  supplyOptimization?: InputMaybe<SupplyOptimizationFilterInput>;
};

export type SupplyOptimizationSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  historyItem?: InputMaybe<HistoryItemSortInput>;
  id?: InputMaybe<SortEnumType>;
  model?: InputMaybe<OnStockModelSortInput>;
  tolerance?: InputMaybe<SortEnumType>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationSortInput>;
};

export type SupplySummaryItemFilterInput = {
  and?: InputMaybe<Array<SupplySummaryItemFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  materialName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SupplySummaryItemFilterInput>>;
  orderedSupplyLengths?: InputMaybe<ListFilterInputTypeOfSupplyLengthFilterInput>;
  sectionName?: InputMaybe<StringOperationFilterInput>;
  supplyOptimization?: InputMaybe<SupplyOptimizationFilterInput>;
};

export enum UserStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export type UserStatusOperationFilterInput = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
};

export type WarehouseObjectFilterInput = {
  and?: InputMaybe<Array<WarehouseObjectFilterInput>>;
  barcode?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  inventoryDate?: InputMaybe<DateTimeOperationFilterInput>;
  length?: InputMaybe<IntOperationFilterInput>;
  material?: InputMaybe<MaterialFilterInput>;
  materialName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseObjectFilterInput>>;
  remark?: InputMaybe<StringOperationFilterInput>;
  section?: InputMaybe<SectionFilterInput>;
  sectionName?: InputMaybe<StringOperationFilterInput>;
  tripleCode?: InputMaybe<StringOperationFilterInput>;
  warehouseObjectMatches?: InputMaybe<ListFilterInputTypeOfWarehouseObjectMatchFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
  weight?: InputMaybe<FloatOperationFilterInput>;
};

export type WarehouseObjectMatchFilterInput = {
  and?: InputMaybe<Array<WarehouseObjectMatchFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseObjectMatchFilterInput>>;
  smadsteelId?: InputMaybe<StringOperationFilterInput>;
  warehouseObject?: InputMaybe<WarehouseObjectFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
  warehouseOptimizationPortion?: InputMaybe<WarehouseOptimizationPortionFilterInput>;
};

export type WarehouseOptimizationFilterInput = {
  and?: InputMaybe<Array<WarehouseOptimizationFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  historyItem?: InputMaybe<HistoryItemFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  mainPriority?: InputMaybe<NullableOfPriorityOperationFilterInput>;
  mainPriorityOrder?: InputMaybe<NullableOfPriorityOrderOperationFilterInput>;
  managerRules?: InputMaybe<ListFilterInputTypeOfManagerRuleFilterInput>;
  matchingMethod?: InputMaybe<MatchingMethodOperationFilterInput>;
  model?: InputMaybe<OnStockModelFilterInput>;
  modelId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseOptimizationFilterInput>>;
  preferExactMatches?: InputMaybe<BooleanOperationFilterInput>;
  secondaryPriority?: InputMaybe<NullableOfPriorityOperationFilterInput>;
  secondaryPriorityOrder?: InputMaybe<NullableOfPriorityOrderOperationFilterInput>;
  smadsteelFile?: InputMaybe<ListByteOperationFilterInput>;
  tolerance?: InputMaybe<IntOperationFilterInput>;
  user?: InputMaybe<OnStockUserFilterInput>;
  warehouseCatalogFile?: InputMaybe<ListByteOperationFilterInput>;
  warehouseCatalogFileName?: InputMaybe<StringOperationFilterInput>;
  warehouseObjectMatches?: InputMaybe<ListFilterInputTypeOfWarehouseObjectMatchFilterInput>;
  warehouseObjects?: InputMaybe<ListFilterInputTypeOfWarehouseObjectFilterInput>;
  warehouseOptimizationPortions?: InputMaybe<ListFilterInputTypeOfWarehouseOptimizationPortionFilterInput>;
  warehouseSummaryItems?: InputMaybe<ListFilterInputTypeOfWarehouseSummaryItemFilterInput>;
};

export type WarehouseOptimizationPortionFilterInput = {
  and?: InputMaybe<Array<WarehouseOptimizationPortionFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseOptimizationPortionFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  portionName?: InputMaybe<StringOperationFilterInput>;
  portionSmadsteelId?: InputMaybe<StringOperationFilterInput>;
  warehouseObjectMatches?: InputMaybe<ListFilterInputTypeOfWarehouseObjectMatchFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
};

export type WarehouseOptimizationSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  historyItem?: InputMaybe<HistoryItemSortInput>;
  id?: InputMaybe<SortEnumType>;
  mainPriority?: InputMaybe<SortEnumType>;
  mainPriorityOrder?: InputMaybe<SortEnumType>;
  matchingMethod?: InputMaybe<SortEnumType>;
  model?: InputMaybe<OnStockModelSortInput>;
  modelId?: InputMaybe<SortEnumType>;
  preferExactMatches?: InputMaybe<SortEnumType>;
  secondaryPriority?: InputMaybe<SortEnumType>;
  secondaryPriorityOrder?: InputMaybe<SortEnumType>;
  tolerance?: InputMaybe<SortEnumType>;
  user?: InputMaybe<OnStockUserSortInput>;
  warehouseCatalogFileName?: InputMaybe<SortEnumType>;
};

export type WarehouseSummaryItemBlockFilterInput = {
  and?: InputMaybe<Array<WarehouseSummaryItemBlockFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  length?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseSummaryItemBlockFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  warehouseSummaryItem?: InputMaybe<WarehouseSummaryItemFilterInput>;
  warehouseSummaryItemId?: InputMaybe<IntOperationFilterInput>;
};

export type WarehouseSummaryItemBlockSortInput = {
  id?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  warehouseSummaryItem?: InputMaybe<WarehouseSummaryItemSortInput>;
  warehouseSummaryItemId?: InputMaybe<SortEnumType>;
};

export type WarehouseSummaryItemFilterInput = {
  and?: InputMaybe<Array<WarehouseSummaryItemFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  length?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WarehouseSummaryItemFilterInput>>;
  quantity?: InputMaybe<IntOperationFilterInput>;
  type?: InputMaybe<SummaryTypeOperationFilterInput>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationFilterInput>;
  warehouseOptimizationId?: InputMaybe<IntOperationFilterInput>;
  warehouseSummaryItemBlocks?: InputMaybe<ListFilterInputTypeOfWarehouseSummaryItemBlockFilterInput>;
};

export type WarehouseSummaryItemSortInput = {
  id?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  warehouseOptimization?: InputMaybe<WarehouseOptimizationSortInput>;
  warehouseOptimizationId?: InputMaybe<SortEnumType>;
};

export type OnStockUser = { id: string, status: UserStatus, name?: string | null, email?: string | null, roles?: Array<string | null> | null, ownedProjects: Array<{ id: number }> };

export type OnStockProjectModel = { id: number, steelspaceId: string, status: OnStockModelStatus };

export type OnStockProject = { id: number, name: string, description: string, creationDate: any, ownerId: string, owner: { id: string }, collaborators: Array<{ id: string, name?: string | null, email?: string | null, roles?: Array<string | null> | null }>, models: Array<OnStockProjectModel> };

export type ManagerRuleParameter = { id: number, direction: NeighbourDirection, step: number, sectionParameter: { id: number, normalizedName: string } };

export type ManagerRule = { id: number, materialDirection: NeighbourDirection, materialStep: number, name: string, warehouseOptimizations: Array<WarehouseOptimization>, managerRuleParameters: Array<{ id: number, direction: NeighbourDirection, step: number, sectionParameter: { id: number, normalizedName: string } }>, sectionType?: { id: number, name: string, path: string, normalizedName: string, sectionFamily: { id: number, name: string, normalizedName: string }, sections: Array<{ id: number, normalizedName: string, name: string }> } | null };

export type EngineerRule = { id: number, portionSmadsteelId: string, portionName: string, condition: EngineerRuleCondition };

export type HistoryItemModel = { projectId: number };

export type HistoryItem = { id: number, steelspaceId: string, modelId: number, warehouseOptimizationId?: number | null, supplyOptimizationId?: number | null, model: HistoryItemModel };

export type OnStockModel = { id: number, steelspaceId: string, status: OnStockModelStatus, importDate: any, projectId: number, project: OnStockProject, warehouseOptimizations: Array<WarehouseOptimization>, supplyOptimizations: Array<SupplyOptimization>, engineerRules: Array<EngineerRule>, managerRules: Array<ManagerRule>, historyItems: Array<HistoryItem>, supplyDefaultLengths: Array<{ id: number, length: number }>, supplyDefaultWaste?: { id: number, waste: number } | null };

export type Material = { id: number, normalizedName: string, name: string };

export type SectionBank = { id: number, normalizedName: string, name: string };

export type SectionOrigin = { id: number, normalizedName: string, name: string };

export type SectionFamily = { id: number, normalizedName: string, name: string };

export type SectionType = { id: number, normalizedName: string, name: string };

export type SectionParameter = { id: number, normalizedName: string, name: string };

export type Section = { id: number, normalizedName: string, name: string };

export type GetCollaboratedProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCollaboratedProjectsQuery = { collaboratedProjects: Array<OnStockProject> };

export type GetOwnedProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnedProjectsQuery = { myProjects: Array<OnStockProject> };

export type GetOnStockUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnStockUsersQuery = { users: Array<OnStockUser> };

export type GetModelBySteelspaceIdQueryVariables = Exact<{
  steelspaceId: Scalars['String']['input'];
}>;


export type GetModelBySteelspaceIdQuery = { accessibleModels: Array<OnStockModel> };

export type GetMaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaterialsQuery = { materialBanks: Array<{ materials: Array<Material> }> };

export type GetLatestSectionBankQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestSectionBankQuery = { sectionBanks?: { nodes?: Array<SectionBank> | null } | null };

export type GetSectionOriginsQueryVariables = Exact<{
  bankName: Scalars['String']['input'];
}>;


export type GetSectionOriginsQuery = { sectionOrigins: Array<SectionOrigin> };

export type GetSectionFamiliesQueryVariables = Exact<{
  bankName: Scalars['String']['input'];
  originName: Scalars['String']['input'];
}>;


export type GetSectionFamiliesQuery = { sectionFamilies: Array<SectionFamily> };

export type GetSectionTypesQueryVariables = Exact<{
  bankName: Scalars['String']['input'];
  originName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
}>;


export type GetSectionTypesQuery = { sectionTypes: Array<SectionType> };

export type GetSectionsQueryVariables = Exact<{
  bankName: Scalars['String']['input'];
  originName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
  endCursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSectionsQuery = { sections?: { nodes?: Array<{ id: number, normalizedName: string, name: string }> | null, pageInfo: { endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetSectionParametersQueryVariables = Exact<{
  bankName: Scalars['String']['input'];
  originName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  typeName: Scalars['String']['input'];
}>;


export type GetSectionParametersQuery = { sectionParameters: Array<{ id: number, normalizedName: string, name: string }> };

export type SupplySummaryItem = { id: number, sectionName: string, materialName: string, orderedSupplyLengths: Array<{ id: number, length: number, waste: number }> };

export type SupplyLength = { id: number, length: number, supplySummaryItem: SupplySummaryItem };

export type SupplyObjectMatch = { id: number, memberSmadsteelIds: Array<string>, supplyLength: SupplyLength };

export type SupplyPortion = { id: number, portionSmadsteelId: string, portionName: string, order: number, supplyObjectMatches: Array<SupplyObjectMatch> };

export type SupplyOptimization = { id: number, creationDate: any, warehouseOptimization?: { id: number } | null, historyItem?: HistoryItem | null, supplySummaryItems: Array<SupplySummaryItem>, optimizationPortions: Array<SupplyPortion>, objectMatches: Array<SupplyObjectMatch> };

export type GetLatestSupplyOptimizationQueryVariables = Exact<{
  steelspaceId: Scalars['String']['input'];
}>;


export type GetLatestSupplyOptimizationQuery = { modelSupplyOptimizations?: { nodes?: Array<SupplyOptimization> | null } | null };

export type WarehouseSummaryItemBlock = { id: number, warehouseSummaryItemId: number, length: number, quantity: number };

export type WarehouseObject = { id: number, sectionName: string, materialName: string, length: number, tripleCode: string, barcode: number, remark: string, inventoryDate: any, section?: { fullName: string } | null, material?: { fullName: string } | null };

export type WarehouseObjectMatch = { id: number, smadsteelId: string, warehouseObject: WarehouseObject };

export type WarehouseSummaryItem = { id: number, type: SummaryType, name: string, quantity: number, length: number, warehouseOptimizationId: number, warehouseSummaryItemBlocks: Array<WarehouseSummaryItemBlock> };

export type WarehousePortion = { id: number, portionSmadsteelId: string, portionName: string, order: number, warehouseObjectMatches: Array<WarehouseObjectMatch> };

export type WarehouseOptimization = { id: number, creationDate: any, modelId: number, matchingMethod: MatchingMethod, mainPriority?: Priority | null, secondaryPriority?: Priority | null, mainPriorityOrder?: PriorityOrder | null, secondaryPriorityOrder?: PriorityOrder | null, tolerance: number, warehouseCatalogFileName: string, user: { id: string, name?: string | null, email?: string | null }, warehouseOptimizationPortions: Array<WarehousePortion>, warehouseSummaryItems: Array<WarehouseSummaryItem>, historyItem?: HistoryItem | null, warehouseObjectMatches: Array<WarehouseObjectMatch> };

export type GetLatestWarehouseOptimizationQueryVariables = Exact<{
  steelspaceId: Scalars['String']['input'];
}>;


export type GetLatestWarehouseOptimizationQuery = { modelWarehouseOptimizations?: { nodes?: Array<WarehouseOptimization> | null } | null };

export type GetWarehouseOptimizationForHistoryItemQueryVariables = Exact<{
  steelspaceId: Scalars['String']['input'];
  firstModelSteelspaceId: Scalars['String']['input'];
}>;


export type GetWarehouseOptimizationForHistoryItemQuery = { modelWarehouseOptimizations?: { nodes?: Array<WarehouseOptimization> | null } | null };

export const OnStockUser = gql`
    fragment OnStockUser on OnStockUser {
  id
  status
  name
  email
  roles
  ownedProjects {
    id
  }
}
    `;
export const ManagerRuleParameter = gql`
    fragment ManagerRuleParameter on ManagerRuleParameter {
  id
  direction
  sectionParameter {
    id
    normalizedName
  }
  step
}
    `;
export const OnStockProjectModel = gql`
    fragment OnStockProjectModel on OnStockModel {
  id
  steelspaceId
  status
}
    `;
export const OnStockProject = gql`
    fragment OnStockProject on OnStockProject {
  id
  name
  description
  creationDate
  ownerId
  owner {
    id
  }
  collaborators {
    id
    name
    email
    roles
  }
  owner {
    id
  }
  models {
    ...OnStockProjectModel
  }
}
    ${OnStockProjectModel}`;
export const WarehouseObject = gql`
    fragment WarehouseObject on WarehouseObject {
  id
  sectionName
  materialName
  length
  tripleCode
  barcode
  remark
  inventoryDate
  section {
    fullName
  }
  material {
    fullName
  }
}
    `;
export const WarehouseObjectMatch = gql`
    fragment WarehouseObjectMatch on WarehouseObjectMatch {
  id
  smadsteelId
  warehouseObject {
    ...WarehouseObject
  }
}
    ${WarehouseObject}`;
export const WarehousePortion = gql`
    fragment WarehousePortion on WarehouseOptimizationPortion {
  id
  portionSmadsteelId
  portionName
  order
  warehouseObjectMatches {
    ...WarehouseObjectMatch
  }
}
    ${WarehouseObjectMatch}`;
export const WarehouseSummaryItemBlock = gql`
    fragment WarehouseSummaryItemBlock on WarehouseSummaryItemBlock {
  id
  warehouseSummaryItemId
  length
  quantity
}
    `;
export const WarehouseSummaryItem = gql`
    fragment WarehouseSummaryItem on WarehouseSummaryItem {
  id
  type
  name
  quantity
  length
  warehouseOptimizationId
  warehouseSummaryItemBlocks(order: {length: DESC}) {
    ...WarehouseSummaryItemBlock
  }
}
    ${WarehouseSummaryItemBlock}`;
export const HistoryItemModel = gql`
    fragment HistoryItemModel on OnStockModel {
  projectId
}
    `;
export const HistoryItem = gql`
    fragment HistoryItem on HistoryItem {
  id
  steelspaceId
  modelId
  warehouseOptimizationId
  supplyOptimizationId
  model {
    ...HistoryItemModel
  }
}
    ${HistoryItemModel}`;
export const WarehouseOptimization = gql`
    fragment WarehouseOptimization on WarehouseOptimization {
  id
  creationDate
  modelId
  user {
    id
    name
    email
  }
  matchingMethod
  mainPriority
  secondaryPriority
  mainPriorityOrder
  secondaryPriorityOrder
  tolerance
  warehouseCatalogFileName
  warehouseOptimizationPortions {
    ...WarehousePortion
  }
  warehouseSummaryItems {
    ...WarehouseSummaryItem
  }
  historyItem {
    ...HistoryItem
  }
  warehouseObjectMatches {
    ...WarehouseObjectMatch
  }
}
    ${WarehousePortion}
${WarehouseSummaryItem}
${HistoryItem}
${WarehouseObjectMatch}`;
export const SupplySummaryItem = gql`
    fragment SupplySummaryItem on SupplySummaryItem {
  id
  sectionName
  materialName
  orderedSupplyLengths {
    id
    length
    waste
  }
}
    `;
export const SupplyLength = gql`
    fragment SupplyLength on SupplyLength {
  id
  length
  supplySummaryItem {
    ...SupplySummaryItem
  }
}
    ${SupplySummaryItem}`;
export const SupplyObjectMatch = gql`
    fragment SupplyObjectMatch on SupplyObjectMatch {
  id
  memberSmadsteelIds
  supplyLength {
    ...SupplyLength
  }
}
    ${SupplyLength}`;
export const SupplyPortion = gql`
    fragment SupplyPortion on SupplyOptimizationPortion {
  id
  portionSmadsteelId
  portionName
  order
  supplyObjectMatches {
    ...SupplyObjectMatch
  }
}
    ${SupplyObjectMatch}`;
export const SupplyOptimization = gql`
    fragment SupplyOptimization on SupplyOptimization {
  id
  creationDate
  warehouseOptimization {
    id
  }
  historyItem {
    ...HistoryItem
  }
  supplySummaryItems {
    ...SupplySummaryItem
  }
  optimizationPortions {
    ...SupplyPortion
  }
  objectMatches {
    ...SupplyObjectMatch
  }
}
    ${HistoryItem}
${SupplySummaryItem}
${SupplyPortion}
${SupplyObjectMatch}`;
export const EngineerRule = gql`
    fragment EngineerRule on EngineerRule {
  id
  portionSmadsteelId
  portionName
  condition
}
    `;
export const ManagerRule = gql`
    fragment ManagerRule on ManagerRule {
  id
  warehouseOptimizations {
    ...WarehouseOptimization
  }
  managerRuleParameters {
    id
    direction
    sectionParameter {
      id
      normalizedName
    }
    step
  }
  materialDirection
  materialStep
  name
  sectionType {
    id
    name
    path
    normalizedName
    sectionFamily {
      id
      name
      normalizedName
    }
    sections {
      id
      normalizedName
      name
    }
  }
}
    ${WarehouseOptimization}`;
export const OnStockModel = gql`
    fragment OnStockModel on OnStockModel {
  id
  steelspaceId
  status
  importDate
  projectId
  project {
    ...OnStockProject
  }
  warehouseOptimizations {
    ...WarehouseOptimization
  }
  supplyOptimizations {
    ...SupplyOptimization
  }
  engineerRules {
    ...EngineerRule
  }
  managerRules {
    ...ManagerRule
  }
  historyItems {
    ...HistoryItem
  }
  supplyDefaultLengths {
    id
    length
  }
  supplyDefaultWaste {
    id
    waste
  }
}
    ${OnStockProject}
${WarehouseOptimization}
${SupplyOptimization}
${EngineerRule}
${ManagerRule}
${HistoryItem}`;
export const Material = gql`
    fragment Material on Material {
  id
  normalizedName
  name
}
    `;
export const SectionBank = gql`
    fragment SectionBank on SectionBank {
  id
  normalizedName
  name
}
    `;
export const SectionOrigin = gql`
    fragment SectionOrigin on SectionOrigin {
  id
  normalizedName
  name
}
    `;
export const SectionFamily = gql`
    fragment SectionFamily on SectionFamily {
  id
  normalizedName
  name
}
    `;
export const SectionType = gql`
    fragment SectionType on SectionType {
  id
  normalizedName
  name
}
    `;
export const SectionParameter = gql`
    fragment SectionParameter on SectionParameter {
  id
  normalizedName
  name
}
    `;
export const Section = gql`
    fragment Section on Section {
  id
  normalizedName
  name
}
    `;
export const GetCollaboratedProjectsQueryDocument = gql`
    query getCollaboratedProjectsQuery {
  collaboratedProjects {
    ...OnStockProject
  }
}
    ${OnStockProject}`;
export const GetOwnedProjectsQueryDocument = gql`
    query getOwnedProjectsQuery {
  myProjects {
    ...OnStockProject
  }
}
    ${OnStockProject}`;
export const GetOnStockUsersQueryDocument = gql`
    query getOnStockUsersQuery {
  users {
    ...OnStockUser
  }
}
    ${OnStockUser}`;
export const GetModelBySteelspaceIdQueryDocument = gql`
    query getModelBySteelspaceIdQuery($steelspaceId: String!) {
  accessibleModels(where: {steelspaceId: {eq: $steelspaceId}}) {
    ...OnStockModel
  }
}
    ${OnStockModel}`;
export const GetMaterialsQueryDocument = gql`
    query getMaterialsQuery {
  materialBanks {
    materials {
      ...Material
    }
  }
}
    ${Material}`;
export const GetLatestSectionBankQueryDocument = gql`
    query getLatestSectionBankQuery {
  sectionBanks(order: {creationDate: ASC}, last: 1) {
    nodes {
      ...SectionBank
    }
  }
}
    ${SectionBank}`;
export const GetSectionOriginsQueryDocument = gql`
    query getSectionOriginsQuery($bankName: String!) {
  sectionOrigins(bankName: $bankName) {
    ...SectionOrigin
  }
}
    ${SectionOrigin}`;
export const GetSectionFamiliesQueryDocument = gql`
    query getSectionFamiliesQuery($bankName: String!, $originName: String!) {
  sectionFamilies(bankName: $bankName, originNormalizedName: $originName) {
    ...SectionFamily
  }
}
    ${SectionFamily}`;
export const GetSectionTypesQueryDocument = gql`
    query getSectionTypesQuery($bankName: String!, $originName: String!, $familyName: String!) {
  sectionTypes(
    bankName: $bankName
    originNormalizedName: $originName
    familyNormalizedName: $familyName
  ) {
    ...SectionType
  }
}
    ${SectionType}`;
export const GetSectionsQueryDocument = gql`
    query getSectionsQuery($bankName: String!, $originName: String!, $familyName: String!, $typeName: String!, $endCursor: String) {
  sections(
    bankName: $bankName
    originNormalizedName: $originName
    familyNormalizedName: $familyName
    typeNormalizedName: $typeName
    first: 50
    after: $endCursor
  ) {
    nodes {
      id
      normalizedName
      name
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export const GetSectionParametersQueryDocument = gql`
    query getSectionParametersQuery($bankName: String!, $originName: String!, $familyName: String!, $typeName: String!) {
  sectionParameters(
    bankName: $bankName
    originNormalizedName: $originName
    familyNormalizedName: $familyName
    typeNormalizedName: $typeName
  ) {
    id
    normalizedName
    name
  }
}
    `;
export const GetLatestSupplyOptimizationQueryDocument = gql`
    query getLatestSupplyOptimizationQuery($steelspaceId: String!) {
  modelSupplyOptimizations(
    where: {model: {steelspaceId: {eq: $steelspaceId}}}
    order: {creationDate: DESC}
    first: 1
  ) {
    nodes {
      ...SupplyOptimization
    }
  }
}
    ${SupplyOptimization}`;
export const GetLatestWarehouseOptimizationQueryDocument = gql`
    query getLatestWarehouseOptimizationQuery($steelspaceId: String!) {
  modelWarehouseOptimizations(
    where: {model: {steelspaceId: {eq: $steelspaceId}}}
    order: {creationDate: DESC}
    first: 1
  ) {
    nodes {
      ...WarehouseOptimization
    }
  }
}
    ${WarehouseOptimization}`;
export const GetWarehouseOptimizationForHistoryItemQueryDocument = gql`
    query getWarehouseOptimizationForHistoryItemQuery($steelspaceId: String!, $firstModelSteelspaceId: String!) {
  modelWarehouseOptimizations(
    where: {and: [{model: {steelspaceId: {eq: $steelspaceId}}}, {historyItem: {steelspaceId: {eq: $firstModelSteelspaceId}}}]}
  ) {
    nodes {
      ...WarehouseOptimization
    }
  }
}
    ${WarehouseOptimization}`;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getCollaboratedProjectsQuery(variables?: GetCollaboratedProjectsQueryVariables, options?: C): Promise<GetCollaboratedProjectsQuery> {
      return requester<GetCollaboratedProjectsQuery, GetCollaboratedProjectsQueryVariables>(GetCollaboratedProjectsQueryDocument, variables, options) as Promise<GetCollaboratedProjectsQuery>;
    },
    getOwnedProjectsQuery(variables?: GetOwnedProjectsQueryVariables, options?: C): Promise<GetOwnedProjectsQuery> {
      return requester<GetOwnedProjectsQuery, GetOwnedProjectsQueryVariables>(GetOwnedProjectsQueryDocument, variables, options) as Promise<GetOwnedProjectsQuery>;
    },
    getOnStockUsersQuery(variables?: GetOnStockUsersQueryVariables, options?: C): Promise<GetOnStockUsersQuery> {
      return requester<GetOnStockUsersQuery, GetOnStockUsersQueryVariables>(GetOnStockUsersQueryDocument, variables, options) as Promise<GetOnStockUsersQuery>;
    },
    getModelBySteelspaceIdQuery(variables: GetModelBySteelspaceIdQueryVariables, options?: C): Promise<GetModelBySteelspaceIdQuery> {
      return requester<GetModelBySteelspaceIdQuery, GetModelBySteelspaceIdQueryVariables>(GetModelBySteelspaceIdQueryDocument, variables, options) as Promise<GetModelBySteelspaceIdQuery>;
    },
    getMaterialsQuery(variables?: GetMaterialsQueryVariables, options?: C): Promise<GetMaterialsQuery> {
      return requester<GetMaterialsQuery, GetMaterialsQueryVariables>(GetMaterialsQueryDocument, variables, options) as Promise<GetMaterialsQuery>;
    },
    getLatestSectionBankQuery(variables?: GetLatestSectionBankQueryVariables, options?: C): Promise<GetLatestSectionBankQuery> {
      return requester<GetLatestSectionBankQuery, GetLatestSectionBankQueryVariables>(GetLatestSectionBankQueryDocument, variables, options) as Promise<GetLatestSectionBankQuery>;
    },
    getSectionOriginsQuery(variables: GetSectionOriginsQueryVariables, options?: C): Promise<GetSectionOriginsQuery> {
      return requester<GetSectionOriginsQuery, GetSectionOriginsQueryVariables>(GetSectionOriginsQueryDocument, variables, options) as Promise<GetSectionOriginsQuery>;
    },
    getSectionFamiliesQuery(variables: GetSectionFamiliesQueryVariables, options?: C): Promise<GetSectionFamiliesQuery> {
      return requester<GetSectionFamiliesQuery, GetSectionFamiliesQueryVariables>(GetSectionFamiliesQueryDocument, variables, options) as Promise<GetSectionFamiliesQuery>;
    },
    getSectionTypesQuery(variables: GetSectionTypesQueryVariables, options?: C): Promise<GetSectionTypesQuery> {
      return requester<GetSectionTypesQuery, GetSectionTypesQueryVariables>(GetSectionTypesQueryDocument, variables, options) as Promise<GetSectionTypesQuery>;
    },
    getSectionsQuery(variables: GetSectionsQueryVariables, options?: C): Promise<GetSectionsQuery> {
      return requester<GetSectionsQuery, GetSectionsQueryVariables>(GetSectionsQueryDocument, variables, options) as Promise<GetSectionsQuery>;
    },
    getSectionParametersQuery(variables: GetSectionParametersQueryVariables, options?: C): Promise<GetSectionParametersQuery> {
      return requester<GetSectionParametersQuery, GetSectionParametersQueryVariables>(GetSectionParametersQueryDocument, variables, options) as Promise<GetSectionParametersQuery>;
    },
    getLatestSupplyOptimizationQuery(variables: GetLatestSupplyOptimizationQueryVariables, options?: C): Promise<GetLatestSupplyOptimizationQuery> {
      return requester<GetLatestSupplyOptimizationQuery, GetLatestSupplyOptimizationQueryVariables>(GetLatestSupplyOptimizationQueryDocument, variables, options) as Promise<GetLatestSupplyOptimizationQuery>;
    },
    getLatestWarehouseOptimizationQuery(variables: GetLatestWarehouseOptimizationQueryVariables, options?: C): Promise<GetLatestWarehouseOptimizationQuery> {
      return requester<GetLatestWarehouseOptimizationQuery, GetLatestWarehouseOptimizationQueryVariables>(GetLatestWarehouseOptimizationQueryDocument, variables, options) as Promise<GetLatestWarehouseOptimizationQuery>;
    },
    getWarehouseOptimizationForHistoryItemQuery(variables: GetWarehouseOptimizationForHistoryItemQueryVariables, options?: C): Promise<GetWarehouseOptimizationForHistoryItemQuery> {
      return requester<GetWarehouseOptimizationForHistoryItemQuery, GetWarehouseOptimizationForHistoryItemQueryVariables>(GetWarehouseOptimizationForHistoryItemQueryDocument, variables, options) as Promise<GetWarehouseOptimizationForHistoryItemQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;