import { doRequest, downloadFile } from '../../../services/requests'
import {
  SteelspaceFileResponse,
  SteelspacePreviewResponse,
  SteelspaceResponse,
} from '../../../types'
import { SteelspaceModel } from '../types/'
import { FileUpdateResult, MoveModelDto, SteelspaceModelDownloadLinkResponse } from '../types'

export const updateModelFilesRequest = async (
  modelId: string,
  previousChecksum: string,
  modelName: string,
  modelDescription: string,
  modelTags: string[],
  modelFiles: File[]
): Promise<FileUpdateResult | undefined> => {
  const formData = new FormData()
  formData.append('PreviousChecksum', previousChecksum)
  formData.append('Name', modelName)
  formData.append('Description', modelDescription)
  modelTags.forEach((tag) => formData.append('Tags[]', tag))
  modelFiles.forEach((modelFile) => formData.append('Files', modelFile))

  const response = await doRequest(
    `update/${modelId}`,
    'POST',
    formData,
    process.env.VUE_APP_CLOUD_SAVE_API_BASEURL,
    false,
    false
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: FileUpdateResult = <FileUpdateResult>responseJson.payload
    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const moveModelRequest = async (
  modelId: string,
  newParentId: string
): Promise<SteelspaceResponse | undefined> => {
  const dto = new MoveModelDto(newParentId)
  const response = await doRequest(`v2/users/me/models/${modelId}`, 'PUT', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const deleteModelRequest = async (
  modelId: string
): Promise<SteelspaceResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}`, 'DELETE')
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const getDownloadLinkToModelFileRequest = async (
  modelId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/download`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: SteelspaceModelDownloadLinkResponse = <{ url: string }>responseJson.payload
    return result
  } else {
    return undefined
  }
}

export const getDownloadLinkToModelFileByFileIdRequest = async (
  modelId: string,
  fileId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/files/${fileId}/download`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: SteelspaceModelDownloadLinkResponse = <{ url: string }>responseJson.payload
    return result
  } else {
    return undefined
  }
}

export const getModelFileRequest = async (
  modelId: string
): Promise<SteelspaceFileResponse | undefined> => {
  const getDownloadLinkResponse = await getDownloadLinkToModelFileRequest(modelId)
  if (!getDownloadLinkResponse) return undefined
  const request = async () => {
    return await fetch(getDownloadLinkResponse.url, {
      method: 'GET',
      mode: 'cors',
    })
  }
  const response = await request()
  if (!response.ok) return undefined
  const buffer = await response.arrayBuffer()
  const result: SteelspaceFileResponse = {
    file: new Blob([buffer], { type: 'application/octet-stream' }),
  }
  return result
}

export const getModelFileByFileIdRequest = async (
  modelId: string,
  fileId: string
): Promise<SteelspaceFileResponse | undefined> => {
  const getDownloadLinkResponse = await getDownloadLinkToModelFileByFileIdRequest(modelId, fileId)
  if (!getDownloadLinkResponse) return undefined
  const request = async () => {
    return await fetch(getDownloadLinkResponse.url, {
      method: 'GET',
      mode: 'cors',
    })
  }
  const response = await request()
  if (!response.ok) return undefined
  const buffer = await response.arrayBuffer()
  const result: SteelspaceFileResponse = {
    file: new Blob([buffer], { type: 'application/octet-stream' }),
  }
  return result
}

export const getModelPreviewUrlRequest = async (
  modelId: string
): Promise<SteelspacePreviewResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/preview`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return (<{ url: string }>responseJson.payload) as SteelspacePreviewResponse
  } else {
    return undefined
  }
}

export const exportMaterialListRequest = async (
  model: SteelspaceModel
): Promise<void | undefined> => {
  const response = await doRequest(
    `export/${model.id}/excel`,
    'GET',
    undefined,
    process.env.VUE_APP_CLOUD_SAVE_API_BASEURL,
    false,
    false,
    '2.0'
  )

  if (response.ok) {
    downloadFile(await response.blob(), `material_list_${model.name}.xlsx`)
  }
}
