import { getApolloSdk } from '@/modules/graphql/apollo.client'
import {
  addItemsToSelection,
  modelViewerStore,
  removeItemsFromSelection,
  setIsolation,
} from '@/store/modelViewer.store'
import { connectionOff, connectionOn, createHub, startHub, stopHub } from '../../services'
import {
  EngineerRule,
  EngineerRuleCondition,
  HistoryItem,
  ManagerRule,
  ManagerRuleParameter,
  Material,
  OnStockModel,
  OnStockModelStatus,
  OnStockProject,
  OnStockProjectModel,
  OnStockUser,
  Section,
  SectionBank,
  SectionFamily,
  SectionOrigin,
  SectionParameter,
} from '../graphql/graphql.types'
import { getModelByIdAsync } from '../model/model.service'
import { ParameterDirectionsMap, ParameterNamesMap } from './constants'
import { OpenOptions, ParameterDirections, ParameterNames, ParameterSteps } from './enums'
import {
  decreaseOnstockProgress,
  increaseOnstockProgress,
  onStockStore,
  setCollaboratedProjects,
  setMyProjects,
  setOnStockError,
  setOnStockHub,
  setOnStockModel,
  setOnStockModelEngineerRules,
  setOnStockProject,
  setOnStockUsers,
} from './onstock.store'
import {
  createOnStockCollaboratorRequest,
  createOnStockHistoryItemRequest,
  createOnStockModelRequest,
  createOnStockProjectRequest,
  createOnStockUserRequest,
  deleteOnStockCollaboratorRequest,
  deleteOnStockHistoryItemRequest,
  deleteOnStockModelRequest,
  deleteOnStockProjectRequest,
  deleteOnStockUserRequest,
  editOnStockProjectRequest,
  getOnStockCurrentUserRequest,
  getOnStockUsersRequest,
  updateOnStockModelRequest,
} from './requests'
import {
  createOnStockEngineerRuleRequest,
  deleteOnStockEngineerRuleRequest,
  updateOnStockEngineerRuleRequest,
} from './requests/onStockEngineerRule.request'
import {
  createManagerRuleRequest,
  createPreviewManagerRuleMaterialRequest,
  createPreviewManagerRuleSectionRequest,
  deleteManagerRuleRequest,
  modifyManagerRuleRequest,
} from './requests/onStockManagerRules.request'
import { OnStockCollaborator, OnStockPermission, OptimizationHubProgress } from './types'
import {
  DefaultManagerRuleData,
  ManagerRuleTableData,
  MaterialManagerRuleParameter,
  ParameterData,
  SectionBankData,
  SectionManagerRuleParameter,
} from './types/OnStockManagerRuleInterfaces'
import {
  OnStockPreviewManagerRuleMaterialModel,
  OnStockPreviewManagerRuleSectionModel,
} from './types/OnStockManagerRules.model'
import { OptimizationHubResult } from './types/OptimizationHubResult'

export type StatusItem = {
  canNavigateTo: {
    permission: OnStockPermission
    status: OnStockModelStatus
  }[]
  text: string
  value: OnStockModelStatus
  color: string
  hidden?: boolean
}

/////////////////////////////////////////
////////HUB REQUESTS/////////////////////
/////////////////////////////////////////
export const startWarehouseOptimization = async (
  warehouseOptimizationId: number,
  progressCallback: (progress: OptimizationHubProgress) => void,
  resultCallback: (progress: OptimizationHubResult) => void
): Promise<void> => {
  increaseOnstockProgress()
  const hub = await createHub('/optimization/warehouse')
  setOnStockHub(hub)

  await startHub(hub)
  connectionOn(hub, 'progress', progressCallback)
  connectionOn(hub, 'result', resultCallback)

  try {
    await hub.invoke('Start', warehouseOptimizationId)
  } catch (error) {
    console.log('Warehouse hub stopped during invoke', error) // eliminate hub stopped during invoke error
  }

  await stopHub(hub)

  decreaseOnstockProgress()
  setOnStockHub(null)
}

export const startSupplyOptimization = async (
  supplyOptimizationId: number,
  progressCallback: (progress: OptimizationHubProgress) => void,
  resultCallback: (progress: OptimizationHubResult) => void
): Promise<void> => {
  increaseOnstockProgress()
  const hub = await createHub('/optimization/supply')
  setOnStockHub(hub)

  await startHub(hub)
  connectionOn(hub, 'progress', progressCallback)
  connectionOn(hub, 'result', resultCallback)

  try {
    await hub.invoke('Start', supplyOptimizationId)
  } catch (error) {
    console.log('Supply hub stopped during invoke', error) // eliminate hub stopped during invoke error
  }

  await stopHub(hub)

  decreaseOnstockProgress()
  setOnStockHub(null)
}

export const terminateHub = async (): Promise<void> => {
  if (onStockStore.hub == null) return
  connectionOff(onStockStore.hub, 'progress')
  connectionOff(onStockStore.hub, 'result')
  await stopHub(onStockStore.hub)
  setOnStockHub(null)
}

///////////////////////////////////////////
/////////REST API REQUESTS//////////////////////
///////////////////////////////////////////

export async function fetchProjects(): Promise<void> {
  await fetchCollaboratedProjects()
  await fetchMyProjects()
}

export function getProjects(): OnStockProject[] {
  return [...onStockStore.myProjects, ...onStockStore.collaboratedProjects]
}

export async function fetchOnStockModelBySteelspaceId(steelspaceId?: string): Promise<void> {
  if (!steelspaceId) {
    console.warn('Steelspace id not given')
    return
  }

  increaseOnstockProgress()
  const relevantModel = await getModelByIdAsync(steelspaceId)

  const relevantModelId = relevantModel.isOrigin ? steelspaceId : relevantModel.originModelId

  const response = await getApolloSdk().getModelBySteelspaceIdQuery({
    steelspaceId: relevantModelId,
  })

  const onStockModel = response.accessibleModels?.[0]

  if (onStockModel) {
    setOnStockModel(onStockModel)
    setOnStockProject(onStockModel.project)
  }
  decreaseOnstockProgress()
}

export function setOnstockModelStatus(status: OnStockModelStatus): void {
  if (onStockStore.onStockModel) onStockStore.onStockModel.status = status
}

export async function fetchMyProjects(): Promise<void> {
  increaseOnstockProgress()

  const response = await getApolloSdk().getOwnedProjectsQuery()

  setMyProjects(response.myProjects)
  decreaseOnstockProgress()
}

export async function fetchCollaboratedProjects(): Promise<void> {
  increaseOnstockProgress()

  const response = await getApolloSdk().getCollaboratedProjectsQuery()

  setCollaboratedProjects(response.collaboratedProjects)
  decreaseOnstockProgress()
}

export const getStatusItems = (): Record<OnStockModelStatus, StatusItem> => {
  return {
    [OnStockModelStatus.WaitingForPreparation]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Tervező,
        },
      ],
      text: 'Waiting for preparation',
      value: OnStockModelStatus.WaitingForPreparation,
      color: '#BA9E39',
    },
    [OnStockModelStatus.WaitingForWarehouseOptimization]: {
      canNavigateTo: [
        { status: OnStockModelStatus.WaitingForApproval, permission: OnStockPermission.Gyártó },
      ],
      text: 'Waiting for optimization',
      value: OnStockModelStatus.WaitingForWarehouseOptimization,
      color: '#6E0BAB',
    },
    [OnStockModelStatus.WaitingForApproval]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Tervező,
        },
        {
          status: OnStockModelStatus.WaitingForFinalizing,
          permission: OnStockPermission.Tervező,
        },
      ],
      text: 'Waiting for approval',
      value: OnStockModelStatus.WaitingForApproval,
      color: '#FFAB48',
    },
    [OnStockModelStatus.WaitingForFinalizing]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Gyártó,
        },
        { status: OnStockModelStatus.Finalized, permission: OnStockPermission.Gyártó },
      ],
      text: 'Waiting for finalization',
      value: OnStockModelStatus.WaitingForFinalizing,
      color: '#57C1F6',
    },
    [OnStockModelStatus.Finalized]: {
      canNavigateTo: [],
      text: 'Finalized',
      value: OnStockModelStatus.Finalized,
      color: '#45C58F',
      hidden: false,
    },
  } as Record<OnStockModelStatus, StatusItem>
}

export async function fetchOnStockUsers(): Promise<void> {
  increaseOnstockProgress()

  const response = await getApolloSdk().getOnStockUsersQuery()

  setOnStockUsers(response.users)

  decreaseOnstockProgress()
}

///////////////////
////USER/////////
//////////////////

export const createOnStockUser = async (
  roles: Array<OnStockPermission>,
  email: string
): Promise<OnStockUser | undefined> => {
  increaseOnstockProgress()
  const result = await createOnStockUserRequest(roles, email)
  decreaseOnstockProgress()
  return result
}

export const deleteOnStockUser = async (id: string): Promise<boolean | undefined> => {
  increaseOnstockProgress()
  const result = await deleteOnStockUserRequest(id)
  decreaseOnstockProgress()
  return result
}

export const getOnStockUsers = async (): Promise<OnStockUser[] | undefined> => {
  increaseOnstockProgress()
  const result = await getOnStockUsersRequest()
  decreaseOnstockProgress()
  return result
}

export const getOnStockCurrentUser = async (): Promise<OnStockUser | undefined> => {
  increaseOnstockProgress()
  const result = await getOnStockCurrentUserRequest()
  decreaseOnstockProgress()
  return result
}

///////////////////
////COLLABORATOR///
//////////////////
export const createOnStockCollaborator = async (
  projectId: string,
  userId: string
): Promise<OnStockCollaborator | undefined> => {
  increaseOnstockProgress()
  const result = await createOnStockCollaboratorRequest(projectId, userId)
  decreaseOnstockProgress()
  return result
}

export const deleteOnStockCollaborator = async (
  projectId: string,
  collaboratorId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()
  const result = await deleteOnStockCollaboratorRequest(projectId, collaboratorId)
  decreaseOnstockProgress()
  return result
}

/////////////////////
////HISTORY ITEM/////
////////////////////

export const createOnStockHistoryItem = async (
  projectId: string,
  modelId: string,
  steelspaceId: string
): Promise<HistoryItem | undefined> => {
  increaseOnstockProgress()

  const historyItem = await createOnStockHistoryItemRequest(projectId, modelId, steelspaceId)
  decreaseOnstockProgress()

  return historyItem
}

export const deleteOnStockHistoryItem = async (
  projectId: string,
  modelId: string,
  historyId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockHistoryItemRequest(projectId, modelId, historyId)
  decreaseOnstockProgress()

  return result
}

/////////////////////
////MODEL/////
////////////////////

export const updateOnStockModel = async (
  projectId: string,
  modelId: string,
  status: OnStockModelStatus,
  checksum?: string
): Promise<OnStockModel | undefined> => {
  increaseOnstockProgress()

  const result = await updateOnStockModelRequest(projectId, modelId, status, checksum)
  decreaseOnstockProgress()

  return result
}

export const createOnStockModel = async (
  projectId: string,
  steelspaceId: string,
  checksum: string
): Promise<OnStockModel | undefined> => {
  increaseOnstockProgress()

  const result = await createOnStockModelRequest(projectId, steelspaceId, checksum)
  decreaseOnstockProgress()

  return result
}

export const deleteOnStockModel = async (
  projectId: string,
  modelId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockModelRequest(projectId, modelId)
  decreaseOnstockProgress()

  return result
}

/////////////////////
////PROJECT/////////
////////////////////

export const createOnStockProject = async (
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  increaseOnstockProgress()

  const result = await createOnStockProjectRequest(name, description)
  decreaseOnstockProgress()

  return result
}

export const editOnStockProject = async (
  projectId: string,
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  increaseOnstockProgress()

  const result = await editOnStockProjectRequest(projectId, name, description)
  decreaseOnstockProgress()

  return result
}

export const deleteOnStockProject = async (id: string): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockProjectRequest(id)
  decreaseOnstockProgress()

  return result
}

////////////////////////////////
//////////MANAGER RULES/////////
////////////////////////////////

export const createPreviewManagerRuleSection = async (
  projectId: string,
  modelId: string,
  sectionId: string,
  sectionParameters: Array<SectionManagerRuleParameter>
): Promise<OnStockPreviewManagerRuleSectionModel[] | undefined> => {
  // TODO: Add loading later
  try {
    const previewManagerRule = await createPreviewManagerRuleSectionRequest(
      projectId,
      modelId,
      sectionId,
      sectionParameters
    )

    return previewManagerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const createPreviewManagerRuleMaterial = async (
  projectId: string,
  modelId: string,
  materialId: string,
  materialParameter: MaterialManagerRuleParameter
): Promise<OnStockPreviewManagerRuleMaterialModel[] | undefined> => {
  // TODO: Add loading later

  try {
    const previewManagerRule = await createPreviewManagerRuleMaterialRequest(
      projectId,
      modelId,
      materialId,
      materialParameter
    )

    return previewManagerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const createManagerRule = async (
  projectId: string,
  modelId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<ManagerRule | undefined> => {
  // TODO: Add loading later

  try {
    const managerRule = await createManagerRuleRequest(
      projectId,
      modelId,
      name,
      sectionTypeId,
      sectionParameters,
      materialParameter
    )

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const deleteManagerRule = async (
  projectId: string,
  modelId: string,
  managerRuleId: string
): Promise<boolean | undefined> => {
  // TODO: Add loading later
  // TODO define return inteface for this

  try {
    const managerRule = await deleteManagerRuleRequest(projectId, modelId, managerRuleId)

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const modifyManagerRule = async (
  projectId: string,
  modelId: string,
  managerRuleId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<ManagerRule | undefined> => {
  // TODO: Add loading later

  try {
    const managerRule = await modifyManagerRuleRequest(
      projectId,
      modelId,
      managerRuleId,
      name,
      sectionTypeId,
      sectionParameters,
      materialParameter
    )

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const getMaterials = async (): Promise<Material[]> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getMaterialsQuery()

  return response.materialBanks[0].materials
}

export const getLatestSectionBank = async (): Promise<SectionBank | undefined> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getLatestSectionBankQuery()

  return response.sectionBanks?.nodes?.[0]
}

export const getSectionOrigins = async (bankName: string): Promise<SectionOrigin[]> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getSectionOriginsQuery({ bankName })

  return response.sectionOrigins
}

export const getSectionFamilies = async (
  bankName: string,
  originName: string
): Promise<SectionFamily[]> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getSectionFamiliesQuery({
    bankName,
    originName,
  })

  return response.sectionFamilies
}

export const getSectionTypes = async (
  bankName: string,
  originName: string,
  familyName: string
): Promise<SectionFamily[]> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getSectionTypesQuery({
    bankName,
    originName,
    familyName,
  })

  return response.sectionTypes
}

export const getSections = async (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string
): Promise<Section[]> => {
  // TODO: Add loading later

  let responseData: Section[] = []
  let hasNextPage = true
  let endCursor: string | null = null

  while (hasNextPage) {
    const response = await getApolloSdk().getSectionsQuery({
      bankName,
      originName,
      familyName,
      typeName,
      endCursor: endCursor,
    })

    const nodes = response.sections?.nodes || []
    const pageInfo = response.sections?.pageInfo

    responseData = [...responseData, ...nodes]
    hasNextPage = pageInfo?.hasNextPage ?? false
    endCursor = pageInfo?.endCursor || ''
  }

  return responseData
}

export const getSectionParameters = async (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string
): Promise<SectionParameter[]> => {
  // TODO: Add loading later

  const response = await getApolloSdk().getSectionParametersQuery({
    bankName,
    originName,
    familyName,
    typeName,
  })

  return response.sectionParameters
}

//////////////////////
///ENGINEER RULES/////
//////////////////////

export const createOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<EngineerRule | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await createOnStockEngineerRuleRequest(
      projectId,
      modelId,
      portionSmadsteelId,
      portionName,
      condition
    )
    if (result && onStockStore.onStockModel) {
      setOnStockModelEngineerRules([...onStockStore.onStockModel.engineerRules, result])
    }
    decreaseOnstockProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

export const updateOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  ruleId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<EngineerRule | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await updateOnStockEngineerRuleRequest(
      projectId,
      modelId,
      ruleId,
      portionSmadsteelId,
      portionName,
      condition
    )
    decreaseOnstockProgress()

    if (result && onStockStore.onStockModel) {
      const updatedEngineerRule = onStockStore.onStockModel.engineerRules.map((engineerRule) => {
        if (engineerRule.id === ruleId) return result
        return engineerRule
      })

      setOnStockModelEngineerRules(updatedEngineerRule)
    }

    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

export const deleteOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  ruleId: number
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await deleteOnStockEngineerRuleRequest(projectId, modelId, ruleId)
    decreaseOnstockProgress()

    if (result && onStockStore.onStockModel) {
      const updatedRules = onStockStore.onStockModel.engineerRules.filter(
        (rule) => rule.id !== ruleId
      )
      setOnStockModelEngineerRules(updatedRules)
    }
    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

///////////////////
///OTHERS/////////
//////////////////

export function getProjectStatus(models: OnStockProjectModel[]): string {
  const notAllModelsFinalized = models.some(
    (model) => model.status !== OnStockModelStatus.Finalized
  )
  return notAllModelsFinalized || !models.length ? 'In progress' : 'Finalized'
}

export const getOnStockUserRoleByEmail = (userEmail: string): string => {
  const user = onStockStore.onStockUsers.find((user) => user.email === userEmail)
  if (!user) {
    console.warn('OnStock user not found!')
    return ''
  }
  const role = user.roles?.find((role) => role !== OnStockPermission.Admin)
  if (!role) return ''
  return role
}

export const getEngineerRuleConditionText = (condition: EngineerRuleCondition): string => {
  switch (condition) {
    case EngineerRuleCondition.MaterialAndSectionChangeAllowed:
      return 'Both section and material can change'

    case EngineerRuleCondition.NoChangeAllowed:
      return 'Neither the section nor the material can change'

    case EngineerRuleCondition.OnlyMaterialChangeAllowed:
      return 'Section remains the same, but material can change'
  }
}

export const getManagerRuleParameterText = (parameter: ParameterNames): string => {
  switch (parameter) {
    case ParameterNames.HEIGHT:
      return ParameterNamesMap[ParameterNames.HEIGHT]
    case ParameterNames.THICKNESS:
      return ParameterNamesMap[ParameterNames.THICKNESS]
    case ParameterNames.DIAMETER:
      return ParameterNamesMap[ParameterNames.DIAMETER]
    case ParameterNames.WIDTH:
      return ParameterNamesMap[ParameterNames.WIDTH]
    case ParameterNames.MATERIAL:
      return ParameterNamesMap[ParameterNames.MATERIAL]
  }
}

export const chainParameterNames = (
  managerRuleParameters: ManagerRuleParameter[],
  materialStep: number
): string => {
  const parameterNames: string[] = []
  managerRuleParameters.forEach((parameter) => {
    const convertedParameterName = getManagerRuleParameterText(
      parameter.sectionParameter.normalizedName as ParameterNames
    )
    parameterNames.push(convertedParameterName.toLowerCase())
  })
  if (materialStep)
    parameterNames.push(getManagerRuleParameterText(ParameterNames.MATERIAL).toLowerCase())

  return parameterNames
    .map((parameterName, index) =>
      index === 0 ? parameterName.charAt(0).toUpperCase() + parameterName.slice(1) : parameterName
    )
    .join(', ')
}

export const setDefaultManagerRuleDialogDataByRuleId = async (
  openOption: OpenOptions,
  managerRuleId?: number
): Promise<DefaultManagerRuleData> => {
  const defaultManagerRuleDialogData: DefaultManagerRuleData = {
    openOption: OpenOptions.Write,
    managerRuleId: '',
    dialogName: '',
    selectedSectionFamily: { text: '', id: '' },
    parameterSettings: [],
    sectionTableData: [],
  }

  if (!managerRuleId || openOption === OpenOptions.Write) return defaultManagerRuleDialogData

  const managerRuleData = onStockStore.onStockModel?.managerRules.find((rule) => {
    if (rule.id === managerRuleId) return rule
  })

  if (!managerRuleData) return defaultManagerRuleDialogData

  defaultManagerRuleDialogData.managerRuleId = managerRuleId.toString()
  defaultManagerRuleDialogData.dialogName = managerRuleData.name
  defaultManagerRuleDialogData.openOption = openOption

  const parameterSettings: ParameterData[] = []
  if (managerRuleData.sectionType !== null) {
    // specific section selected

    defaultManagerRuleDialogData.selectedSectionFamily = {
      text: managerRuleData.sectionType?.name || '',
      id: '0' + '-' + managerRuleData.sectionType?.id,
    }

    const sectionPath = managerRuleData.sectionType?.path.split('.')
    let allParametersData: SectionBankData[] = []

    if (sectionPath && sectionPath.length === 4)
      allParametersData = await getSectionParameters(
        sectionPath[0],
        sectionPath[1],
        sectionPath[2],
        sectionPath[3]
      )

    managerRuleData.managerRuleParameters.forEach((parameter) => {
      parameterSettings.push({
        id: parameter.sectionParameter.id,
        name: parameter.sectionParameter.normalizedName,
        active: true,
        direction: ParameterDirectionsMap[parameter.direction],
        step: parameter.step,
      } as ParameterData)
    })
    allParametersData.forEach((parameter) => {
      const isParameterStoredBefore = parameterSettings.find(
        (param) => param.name === parameter.normalizedName
      )
      if (!isParameterStoredBefore)
        parameterSettings.push({
          id: parameter.id,
          name: parameter.normalizedName,
          active: false,
          direction: ParameterDirections.UpAndDown,
          step: ParameterSteps.Zero,
        } as ParameterData)
    })

    const sortedTableData = managerRuleData.sectionType?.sections.sort(compareTwoSection) || []
    const sectionData: ManagerRuleTableData[] = []
    sortedTableData.forEach((material) => {
      sectionData.push({
        data: { ...material, id: '0' + '-' + material.id },
        checkedByUser: false,
        readOnlyChecked: false,
      })
    })
    defaultManagerRuleDialogData.sectionTableData = sectionData
  } else {
    // all section option selected (section type is null)

    defaultManagerRuleDialogData.selectedSectionFamily = {
      text: 'All',
      id: '0-all',
    }
    defaultManagerRuleDialogData.sectionTableData = []
  }

  // add material field as last item
  parameterSettings.push({
    id: 0,
    name: ParameterNames.MATERIAL,
    active: !!managerRuleData.materialStep,
    direction: ParameterDirectionsMap[managerRuleData.materialDirection],
    step: managerRuleData.materialStep,
  } as ParameterData)
  defaultManagerRuleDialogData.parameterSettings = parameterSettings

  return defaultManagerRuleDialogData
}

const compareTwoSection = (
  firstSectionItem: SectionBankData,
  secondSectionItem: SectionBankData
): number => {
  const firstData = firstSectionItem.name
    .trim()
    .split('x')
    .map((str) => Number(str))
  const secondData = secondSectionItem.name
    .trim()
    .split('x')
    .map((str) => Number(str))

  if (firstData[0] !== secondData[0]) {
    return firstData[0] - secondData[0]
  }
  if (firstData[1] !== secondData[1]) {
    return firstData[1] - secondData[1]
  }
  return firstData[2] - secondData[2]
}

export const normalizeName = (name: string): string => {
  return name
    .replaceAll(' ', '')
    .replaceAll('.', ',')
    .replaceAll('*', 'x')
    .replaceAll('-', '')
    .toUpperCase()
}

export const isSectionNameEqual = (name1: string, name2: string): boolean => {
  const normalizedName1 = normalizeName(name1)
  const normalizedName2 = normalizeName(name2)

  return (
    normalizedName1.includes(normalizedName2) ||
    normalizedName2.includes(normalizedName1) ||
    [...normalizedName1].sort().join('') === [...normalizedName2].sort().join('')
  )
}

export const setSelectionAndIsolation = (...ids: string[]): void => {
  addItemsToSelection(...ids)
  if (
    modelViewerStore.unselectedMembersToTransparentOption &&
    !modelViewerStore.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}

export const removeSelectionAndIsolation = (...ids: string[]): void => {
  removeItemsFromSelection(...ids)
  if (
    modelViewerStore.unselectedMembersToTransparentOption &&
    !modelViewerStore.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}
