import { getApp } from '@firebase/app'
import { getAuth } from '@firebase/auth'
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore'
import { getModelFromCache, saveModelToCache } from '../../services/cache.service'
import { SteelspaceFileResponse, SteelspaceResponse } from '../../types'
import { onStockStore, updateOnStockModel } from '../onstock'
import {
  modelStore,
  setModel,
  setModelError,
  setModelLoading,
  setModelUnSubs,
  setModels,
  setPublicModels,
} from './model.store'
import {
  addShareRequest,
  deleteModelRequest,
  getDownloadLinkToModelFileRequest,
  getModelFileRequest,
  getModelPreviewUrlRequest,
  modifyShareRequest,
  moveModelRequest,
  removeShareRequest,
  updateModelFilesRequest,
  exportMaterialListRequest,
} from './requests'
import {
  FileUpdateResult,
  SteelspaceModel,
  SteelspaceModelDownloadLinkResponse,
  SteelspaceModelRelatedFile,
  SteelspaceModelUserShare,
} from './types'

/////////////////////////
/////REQUEST CALLS///////
/////////////////////////

////////////SHARE REQUESTS////////////
export const addShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await addShareRequest(modelId, share)
  setModelLoading(false)
  return response
}

export const modifyShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await modifyShareRequest(modelId, share)
  setModelLoading(false)
  return response
}
export const removeShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await removeShareRequest(modelId, share)
  setModelLoading(false)
  return response
}

//////////////MODEL REQEUSTS///////////

export const updateModelFiles = async (
  modelId: string,
  previousChecksum: string,
  modelName: string,
  modelDescription: string,
  modelTags: string[],
  modelFiles: File[]
): Promise<FileUpdateResult | undefined> => {
  try {
    const updatedModel = await updateModelFilesRequest(
      modelId,
      previousChecksum,
      modelName,
      modelDescription,
      modelTags,
      modelFiles
    )
    return updatedModel
  } catch (error) {
    setModelError('Hiba történt a modell feltöltése során')
  }
}

export const moveModel = async (
  modelId: string,
  newParentId: string
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await moveModelRequest(modelId, newParentId)
  setModelLoading(false)
  return response
}

export const deleteModel = async (modelId: string): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await deleteModelRequest(modelId)
  setModelLoading(false)
  return response
}

export const getDownloadLinkToModelFile = async (
  modelId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  setModelLoading(true)
  const response = await getDownloadLinkToModelFileRequest(modelId)
  setModelLoading(false)
  return response
}

export const getModelPreviewUrl = async (modelId: string): Promise<string | undefined> => {
  setModelLoading(true)
  const response = await getModelPreviewUrlRequest(modelId)
  setModelLoading(false)

  return response?.url
}

export const getModelFile = async (
  modelId: string,
  lastModificationDate: number
): Promise<Blob | undefined> => {
  setModelLoading(true)
  let modelFile: SteelspaceFileResponse | undefined
  //we need to wrap this, firefox doesn't let us read IDB if in incognito
  try {
    const cached = await getModelFromCache(modelId, lastModificationDate)

    if (!cached) {
      //not in cache, dowlnload needed
      modelFile = await getModelFileRequest(modelId)
      if (!modelFile) return undefined

      await saveModelToCache(modelId, lastModificationDate, modelFile.file)

      if (modelStore.model && onStockStore.onStockModel) {
        const steelspaceChecksum = modelStore.model.relatedFiles.find(
          (file) => file.extension === 'smadsteel'
        )?.checksum

        if (steelspaceChecksum) {
          await updateOnStockModel(
            onStockStore.onStockModel.projectId.toString(),
            onStockStore.onStockModel.id.toString(),
            onStockStore.onStockModel.status,
            steelspaceChecksum
          )
        }
      }
    } else {
      modelFile = cached
    }
    //if an error occurs, just download the model
  } catch {
    modelFile = await getModelFileRequest(modelId)
  }

  setModelLoading(false)
  return modelFile?.file
}

export const exportMaterialList = async (model: SteelspaceModel): Promise<void> => {
  setModelLoading(true)
  await exportMaterialListRequest(model)
  setModelLoading(false)
}

/////////////////////////
/////FIREBASE SDK//////
////////////////////////
const getModelDocumentById = async (modelId: string): Promise<DocumentData | undefined> => {
  setModelLoading(true)
  const modelDocument = (await getDoc(doc(getFirestore(getApp()), 'models', modelId))).data()
  setModelLoading(false)

  return modelDocument
}

export const getUserModelsSubscribe = (): SteelspaceModel[] => {
  if (!modelStore.models.length) subscribeToModels()

  return modelStore.models
}

export const getPublicModels = (): SteelspaceModel[] | null => {
  if (!modelStore.publicModels?.length) getPublicModelsQuery()

  return modelStore.publicModels
}

export const getModelByIdAsync = async (id?: string): Promise<SteelspaceModel> => {
  if ((!modelStore.model && id) || (id && modelStore.model && modelStore.model.id !== id))
    await getModelDocumentById(id)

  return modelStore.model || new SteelspaceModel()
}

export const getModelByIdSubscribe = (id?: string): SteelspaceModel | null => {
  if ((!modelStore.model && id) || (id && modelStore.model && modelStore.model.id !== id))
    subscribeToModel(id)

  return modelStore.model
}

const getPublicModelsQuery = (): void => {
  setModelLoading(true)
  const queryPublicModels = query(
    collection(getFirestore(getApp()), 'models'),
    where('isPublic', '==', true)
  )

  getDocs(queryPublicModels).then((docs) => {
    setPublicModels([])
    docs.forEach((doc) => {
      const data = doc.data()
      modelStore.publicModels?.push(new SteelspaceModel(data))
    })
    setModelLoading(false)
  })
}

const subscribeToModel = (modelId: string): void => {
  const unsubKey = 'model'

  modelStore.unSubs[unsubKey]?.()

  const modelUnsub = onSnapshot(
    doc(getFirestore(getApp()), 'models', modelId),
    async (doc) => {
      const model = new SteelspaceModel(doc.data())
      const relatedFiles = await getDocs(collection(doc.ref, 'modelFiles'))
      relatedFiles.docs.map((related) =>
        model.relatedFiles.push(related.data() as SteelspaceModelRelatedFile)
      )

      setModel(model)
    },
    (error) => {
      console.log(error.code)
    }
  )

  modelStore.unSubs[unsubKey] = modelUnsub
}

const subscribeToModels = (): void => {
  const unsubKey = 'models'
  if (modelStore.unSubs[unsubKey] || !getAuth().currentUser) {
    return
  }

  const queryModels = query(
    collection(getFirestore(getApp()), 'models'),
    where('userData.id', '==', getAuth().currentUser?.uid)
  )

  const unsubFromModelsUpdates = onSnapshot(queryModels, async (querySnapshot) => {
    setModelLoading(true)

    const promises = querySnapshot.docs.map(async (doc) => {
      const model = new SteelspaceModel(doc.data())
      const relatedFiles = await getDocs(collection(doc.ref, 'modelFiles'))
      relatedFiles.docs.map((related) =>
        model.relatedFiles.push(related.data() as SteelspaceModelRelatedFile)
      )
      return model
    })

    const models = await Promise.all(promises)
    setModels(models)
    setModelLoading(false)
  })

  modelStore.unSubs[unsubKey] = unsubFromModelsUpdates
}

export const unSubFromModelFirebaseSnapshots = (): void => {
  Object.values(modelStore.unSubs).forEach((unsub) => {
    unsub()
  })

  setModelUnSubs({})
}

////////////////////
/////OTHERS////////
///////////////////

export const getModelChecksum = (modelId: string, steelspaceModels: SteelspaceModel[]): string => {
  return (
    steelspaceModels
      .find((model) => model.id === modelId)
      ?.relatedFiles.find((file) => file.extension === 'smadsteel')?.checksum || ''
  )
}

export const getOriginModelId = (modelId: string, steelspaceModels: SteelspaceModel[]): string => {
  const relevantModel = steelspaceModels.find((model) => model.id == modelId)

  if (!relevantModel) return modelId

  return relevantModel.isOrigin ? relevantModel.id : relevantModel.originModelId
}
