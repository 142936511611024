import { WarehouseOptimizationTreeNode } from '@/components/Viewer/WarehouseOptimizationSelectTree.vue'
import {
  ModelStateNumber,
  addItemsToSelection,
  getCompareStateBasedOnFadingLevel,
  modelViewerStore,
  removeItemsFromSelection,
  setIsolation,
} from '@/store/modelViewer.store'
import { Model } from '@consteel/stoke/src/ObjectDataBuilders/SmadsteelObjectBuilder'
import { Get, StructuralMember } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import { getApolloClient } from '../../apollo-client'
import { connectionOff, connectionOn, createHub, startHub, stopHub } from '../../services'
import {
  getCollaboratedProjectsQuery,
  getLatestSectionBankQuery,
  getLatestWarehouseOptimizationQuery,
  getMaterialsQuery,
  getModelBySteelspaceIdQuery,
  getOwnedProjectsQuery,
  getSectionFamiliesQuery,
  getSectionOriginsQuery,
  getSectionParametersQuery,
  getSectionTypesQuery,
  getSectionsQuery,
  getUsersQuery,
  getWarehouseOptimizationForHistoryItemQuery,
} from '../../services/graphql/queries'
import { getModelByIdAsync } from '../model/model.service'
import { ParameterDirectionsMap, ParameterNamesMap } from './constants'
import { OpenOptions, ParameterDirections, ParameterNames, ParameterSteps } from './enums'
import {
  decreaseOnstockProgress,
  increaseOnstockProgress,
  onStockStore,
  setCollaboratedProjects,
  setMyProjects,
  setOnStockError,
  setOnStockHub,
  setOnStockModel,
  setOnStockModelEngineerRules,
  setOnStockProject,
  setOnStockUsers,
  setWarehouseOptimization,
} from './onstock.store'
import {
  createOnStockCollaboratorRequest,
  createOnStockHistoryItemRequest,
  createOnStockModelRequest,
  createOnStockProjectRequest,
  createOnStockUserRequest,
  createWarehouseOptimizationRequest,
  deleteObjectMatchRequest,
  deleteObjectMatchesBasedOnSummaryItemRequest,
  deleteOnStockCollaboratorRequest,
  deleteOnStockHistoryItemRequest,
  deleteOnStockModelRequest,
  deleteOnStockProjectRequest,
  deleteOnStockUserRequest,
  deleteWarehouseObjectsByMatchedWarehouseObjectRequest,
  editOnStockProjectRequest,
  exportWarehouseOptimizationRequest,
  getOnStockCurrentUserRequest,
  getOnStockUsersRequest,
  updateOnStockModelRequest,
} from './requests'
import {
  createOnStockEngineerRuleRequest,
  deleteOnStockEngineerRuleRequest,
  updateOnStockEngineerRuleRequest,
} from './requests/onStockEngineerRule.request'
import {
  createManagerRuleRequest,
  createPreviewManagerRuleMaterialRequest,
  createPreviewManagerRuleSectionRequest,
  deleteManagerRuleRequest,
  modifyManagerRuleRequest,
} from './requests/onStockManagerRules.request'
import {
  OnStockCollaborator,
  OnStockHistoryItem,
  OnStockModel,
  OnStockModelStatus,
  OnStockPermission,
  OnStockProject,
  OnStockUser,
  OnStockWarehouseOptimization,
  OptimizationHubProgress,
  SummaryType,
} from './types'
import { EngineerRuleCondition, OnStockEngineerRule } from './types/OnStockEngineerRule.model'
import {
  DefaultManagerRuleData,
  ManagerRuleTableData,
  MaterialManagerRuleParameter,
  ParameterData,
  SectionBankData,
  SectionManagerRuleParameter,
} from './types/OnStockManagerRuleInterfaces'
import {
  ManagerRuleParameter,
  OnStockManagerRule,
  OnStockPreviewManagerRuleMaterialModel,
  OnStockPreviewManagerRuleSectionModel,
} from './types/OnStockManagerRules.model'
import { OnStockPortionInfo } from './types/OnStockSupplyOptimization/OnStockPortionInfo.model'
import { OptimizationHubResult } from './types/OptimizationHubResult'

export type StatusItem = {
  canNavigateTo: {
    permission: OnStockPermission
    status: OnStockModelStatus
  }[]
  text: string
  value: OnStockModelStatus
  color: string
  hidden?: boolean
}

/////////////////////////////////////////
////////HUB REQUESTS/////////////////////
/////////////////////////////////////////
export const startWarehouseOptimization = async (
  warehouseOptimizationId: number,
  progressCallback: (progress: OptimizationHubProgress) => void,
  resultCallback: (progress: OptimizationHubResult) => void
): Promise<void> => {
  increaseOnstockProgress()
  const hub = await createHub('/optimization/warehouse')
  setOnStockHub(hub)

  await startHub(hub)
  connectionOn(hub, 'progress', progressCallback)
  connectionOn(hub, 'result', resultCallback)

  try {
    await hub.invoke('Start', warehouseOptimizationId)
  } catch (error) {
    console.log('Warehouse hub stopped during invoke', error) // eliminate hub stopped during invoke error
  }

  await stopHub(hub)

  decreaseOnstockProgress()
  setOnStockHub(null)
}

export const startSupplyOptimization = async (
  supplyOptimizationId: number,
  progressCallback: (progress: OptimizationHubProgress) => void,
  resultCallback: (progress: OptimizationHubResult) => void
): Promise<void> => {
  increaseOnstockProgress()
  const hub = await createHub('/optimization/supply')
  setOnStockHub(hub)

  await startHub(hub)
  connectionOn(hub, 'progress', progressCallback)
  connectionOn(hub, 'result', resultCallback)

  try {
    await hub.invoke('Start', supplyOptimizationId)
  } catch (error) {
    console.log('Supply hub stopped during invoke', error) // eliminate hub stopped during invoke error
  }

  await stopHub(hub)

  decreaseOnstockProgress()
  setOnStockHub(null)
}

export const terminateHub = async (): Promise<void> => {
  if (onStockStore.hub == null) return
  connectionOff(onStockStore.hub, 'progress')
  connectionOff(onStockStore.hub, 'result')
  await stopHub(onStockStore.hub)
  setOnStockHub(null)
}

///////////////////////////////////////////
/////////REST API REQUESTS//////////////////////
///////////////////////////////////////////
export function compareWarehouseOptimization(): string[] {
  const changedIds = [] as guid[]

  onStockStore.warehouseOptimization?.warehouseObjectMatches.forEach((objectMatch) => {
    const matchedObjectMatch =
      onStockStore.warehouseOptimizationSecondary?.warehouseObjectMatches.find(
        (objectMatchSecondary) => {
          return objectMatchSecondary.smadsteelId === objectMatch.smadsteelId
        }
      )

    if (!matchedObjectMatch) changedIds.push(objectMatch.smadsteelId)
  })

  onStockStore.warehouseOptimizationSecondary?.warehouseObjectMatches.forEach((objectMatch) => {
    const matchedObjectMatch = onStockStore.warehouseOptimization?.warehouseObjectMatches.find(
      (objectMatchSecondary) => {
        return objectMatchSecondary.smadsteelId === objectMatch.smadsteelId
      }
    )

    if (!matchedObjectMatch && !changedIds.includes(objectMatch.smadsteelId))
      changedIds.push(objectMatch.smadsteelId)
  })

  return changedIds
}

export async function fetchProjects(): Promise<void> {
  await fetchCollaboratedProjects()
  await fetchMyProjects()
}

export function getProjects(): OnStockProject[] {
  return [...onStockStore.myProjects, ...onStockStore.collaboratedProjects]
}

export async function getLatestWarehouseOptimization(): Promise<OnStockWarehouseOptimization> {
  const client = await getApolloClient()
  const response = await client.query({
    query: getLatestWarehouseOptimizationQuery(onStockStore.onStockModel?.steelspaceId || ''),
  })
  return response.data.modelWarehouseOptimizations.nodes[0]
}

export async function getWarehouseOptimization(
  steelspaceHistoryItemId?: string
): Promise<OnStockWarehouseOptimization | undefined> {
  increaseOnstockProgress()
  if (!onStockStore.project) {
    decreaseOnstockProgress()
    return undefined
  }

  if (steelspaceHistoryItemId) {
    decreaseOnstockProgress()
    return onStockStore.onStockModel?.warehouseOptimizations.find(
      (corr) => corr.historyItem?.steelspaceId == steelspaceHistoryItemId
    )
  }

  if (onStockStore.onStockModel?.warehouseOptimizations) {
    decreaseOnstockProgress()
    return await getLatestWarehouseOptimization()
  }
  decreaseOnstockProgress()
  return undefined
}

export async function fetchOnStockModelBySteelspaceId(steelspaceId?: string): Promise<void> {
  if (!steelspaceId) {
    console.warn('Steelspace id not given')
    return
  }

  increaseOnstockProgress()
  const relevantModel = await getModelByIdAsync(steelspaceId)

  const relevantModelId = relevantModel.isOrigin ? steelspaceId : relevantModel.originModelId
  const client = await getApolloClient()
  const response = await client.query({
    query: getModelBySteelspaceIdQuery(relevantModelId),
  })

  const onStockModel = response.data.accessibleModels?.[0] as OnStockModel

  if (onStockModel) {
    setOnStockModel(onStockModel)
    setOnStockProject(onStockModel.project)
  }
  decreaseOnstockProgress()
}

export function setOnstockModelStatus(status: OnStockModelStatus): void {
  if (onStockStore.onStockModel) onStockStore.onStockModel.status = status
}

export async function fetchMyProjects(): Promise<void> {
  increaseOnstockProgress()
  const client = await getApolloClient()
  const response = await client.query({
    query: getOwnedProjectsQuery,
  })

  setMyProjects(response.data.myProjects)
  decreaseOnstockProgress()
}

export async function fetchCollaboratedProjects(): Promise<void> {
  increaseOnstockProgress()
  const client = await getApolloClient()
  const response = await client.query({
    query: getCollaboratedProjectsQuery,
  })

  setCollaboratedProjects(response.data.collaboratedProjects)
  decreaseOnstockProgress()
}

export const getStatusItems = (): Record<OnStockModelStatus, StatusItem> => {
  return {
    [OnStockModelStatus.WaitingForPreparation]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Tervező,
        },
      ],
      text: 'Waiting for preparation',
      value: OnStockModelStatus.WaitingForPreparation,
      color: '#BA9E39',
    },
    [OnStockModelStatus.WaitingForWarehouseOptimization]: {
      canNavigateTo: [
        { status: OnStockModelStatus.WaitingForApproval, permission: OnStockPermission.Gyártó },
      ],
      text: 'Waiting for optimization',
      value: OnStockModelStatus.WaitingForWarehouseOptimization,
      color: '#6E0BAB',
    },
    [OnStockModelStatus.WaitingForApproval]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Tervező,
        },
        {
          status: OnStockModelStatus.WaitingForFinalizing,
          permission: OnStockPermission.Tervező,
        },
      ],
      text: 'Waiting for approval',
      value: OnStockModelStatus.WaitingForApproval,
      color: '#FFAB48',
    },
    [OnStockModelStatus.WaitingForFinalizing]: {
      canNavigateTo: [
        {
          status: OnStockModelStatus.WaitingForWarehouseOptimization,
          permission: OnStockPermission.Gyártó,
        },
        { status: OnStockModelStatus.Finalized, permission: OnStockPermission.Gyártó },
      ],
      text: 'Waiting for finalization',
      value: OnStockModelStatus.WaitingForFinalizing,
      color: '#57C1F6',
    },
    [OnStockModelStatus.Finalized]: {
      canNavigateTo: [],
      text: 'Finalized',
      value: OnStockModelStatus.Finalized,
      color: '#45C58F',
      hidden: false,
    },
  } as Record<OnStockModelStatus, StatusItem>
}

export async function fetchOnStockUsers(): Promise<void> {
  increaseOnstockProgress()
  const client = await getApolloClient()
  const response = await client.query({
    query: getUsersQuery,
  })
  setOnStockUsers(response.data.users)
  decreaseOnstockProgress()
}

///////////////////
////USER/////////
//////////////////

export const createOnStockUser = async (
  roles: Array<OnStockPermission>,
  email: string
): Promise<OnStockUser | undefined> => {
  increaseOnstockProgress()
  const result = await createOnStockUserRequest(roles, email)
  decreaseOnstockProgress()
  return result
}

export const deleteOnStockUser = async (id: string): Promise<boolean | undefined> => {
  increaseOnstockProgress()
  const result = await deleteOnStockUserRequest(id)
  decreaseOnstockProgress()
  return result
}

export const getOnStockUsers = async (): Promise<OnStockUser[] | undefined> => {
  increaseOnstockProgress()
  const result = await getOnStockUsersRequest()
  decreaseOnstockProgress()
  return result
}

export const getOnStockCurrentUser = async (): Promise<OnStockUser | undefined> => {
  increaseOnstockProgress()
  const result = await getOnStockCurrentUserRequest()
  decreaseOnstockProgress()
  return result
}

///////////////////
////COLLABORATOR///
//////////////////
export const createOnStockCollaborator = async (
  projectId: string,
  userId: string
): Promise<OnStockCollaborator | undefined> => {
  increaseOnstockProgress()
  const result = await createOnStockCollaboratorRequest(projectId, userId)
  decreaseOnstockProgress()
  return result
}

export const deleteOnStockCollaborator = async (
  projectId: string,
  collaboratorId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()
  const result = await deleteOnStockCollaboratorRequest(projectId, collaboratorId)
  decreaseOnstockProgress()
  return result
}

/////////////////////////////
////WAREHOUSE OPTIMIZATION///
/////////////////////////////
export const createWarehouseOptimization = async (
  projectId: string,
  modelId: string,
  warehouseCatalog: File,
  modelFile: File,
  tolerance: number,
  method: number,
  mainPriority: number,
  secondaryPriority: number,
  mainPriorityOrder: number,
  secondaryPriorityOrder: number,
  managerRuleIds: number[],
  preferExactMatchesChecked: boolean,
  portions: OnStockPortionInfo[]
): Promise<OnStockWarehouseOptimization | undefined> => {
  increaseOnstockProgress()

  try {
    const warehouseOptimization = await createWarehouseOptimizationRequest(
      projectId,
      modelId,
      warehouseCatalog,
      modelFile,
      tolerance,
      method,
      mainPriority,
      secondaryPriority,
      mainPriorityOrder,
      secondaryPriorityOrder,
      managerRuleIds,
      preferExactMatchesChecked,
      portions
    )

    decreaseOnstockProgress()
    return warehouseOptimization
  } catch (error: any) {
    console.error({ error })
    decreaseOnstockProgress()
    setOnStockError((error as Error).message)
  }
}

export const exportWarehouseOptimization = async (
  projectId: string,
  modelId: string,
  warehouseOptimizationId: string
): Promise<void> => {
  increaseOnstockProgress()

  await exportWarehouseOptimizationRequest(projectId, modelId, warehouseOptimizationId)
  decreaseOnstockProgress()
}

export const getWarehouseOptimizationForHistoryItem = async (
  modelId: string,
  historyItemId: string
): Promise<OnStockWarehouseOptimization> => {
  increaseOnstockProgress()
  const client = await getApolloClient()
  const response = await client.query({
    query: getWarehouseOptimizationForHistoryItemQuery(modelId, historyItemId),
  })

  decreaseOnstockProgress()

  return response.data.modelWarehouseOptimizations.nodes?.[0]
}

/////////////////////
////HISTORY ITEM/////
////////////////////

export const createOnStockHistoryItem = async (
  projectId: string,
  modelId: string,
  steelspaceId: string
): Promise<OnStockHistoryItem | undefined> => {
  increaseOnstockProgress()

  const historyItem = await createOnStockHistoryItemRequest(projectId, modelId, steelspaceId)
  decreaseOnstockProgress()

  return historyItem
}

export const deleteOnStockHistoryItem = async (
  projectId: string,
  modelId: string,
  historyId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockHistoryItemRequest(projectId, modelId, historyId)
  decreaseOnstockProgress()

  return result
}

/////////////////////
////MODEL/////
////////////////////

export const updateOnStockModel = async (
  projectId: string,
  modelId: string,
  status: OnStockModelStatus,
  checksum?: string
): Promise<OnStockModel | undefined> => {
  increaseOnstockProgress()

  const result = await updateOnStockModelRequest(projectId, modelId, status, checksum)
  decreaseOnstockProgress()

  return result
}

export const createOnStockModel = async (
  projectId: string,
  steelspaceId: string,
  checksum: string
): Promise<OnStockModel | undefined> => {
  increaseOnstockProgress()

  const result = await createOnStockModelRequest(projectId, steelspaceId, checksum)
  decreaseOnstockProgress()

  return result
}

export const deleteOnStockModel = async (
  projectId: string,
  modelId: string
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockModelRequest(projectId, modelId)
  decreaseOnstockProgress()

  return result
}

/////////////////////
////PROJECT/////////
////////////////////

export const createOnStockProject = async (
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  increaseOnstockProgress()

  const result = await createOnStockProjectRequest(name, description)
  decreaseOnstockProgress()

  return result
}

export const editOnStockProject = async (
  projectId: string,
  name: string,
  description: string
): Promise<OnStockProject | undefined> => {
  increaseOnstockProgress()

  const result = await editOnStockProjectRequest(projectId, name, description)
  decreaseOnstockProgress()

  return result
}

export const deleteOnStockProject = async (id: string): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  const result = await deleteOnStockProjectRequest(id)
  decreaseOnstockProgress()

  return result
}

////////////////////////////////
//////////MANAGER RULES/////////
////////////////////////////////

export const createPreviewManagerRuleSection = async (
  projectId: string,
  modelId: string,
  sectionId: string,
  sectionParameters: Array<SectionManagerRuleParameter>
): Promise<OnStockPreviewManagerRuleSectionModel[] | undefined> => {
  // TODO: Add loading later
  try {
    const previewManagerRule = await createPreviewManagerRuleSectionRequest(
      projectId,
      modelId,
      sectionId,
      sectionParameters
    )

    return previewManagerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const createPreviewManagerRuleMaterial = async (
  projectId: string,
  modelId: string,
  materialId: string,
  materialParameter: MaterialManagerRuleParameter
): Promise<OnStockPreviewManagerRuleMaterialModel[] | undefined> => {
  // TODO: Add loading later

  try {
    const previewManagerRule = await createPreviewManagerRuleMaterialRequest(
      projectId,
      modelId,
      materialId,
      materialParameter
    )

    return previewManagerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const createManagerRule = async (
  projectId: string,
  modelId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<OnStockManagerRule | undefined> => {
  // TODO: Add loading later

  try {
    const managerRule = await createManagerRuleRequest(
      projectId,
      modelId,
      name,
      sectionTypeId,
      sectionParameters,
      materialParameter
    )

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const deleteManagerRule = async (
  projectId: string,
  modelId: string,
  managerRuleId: string
): Promise<boolean | undefined> => {
  // TODO: Add loading later
  // TODO define return inteface for this

  try {
    const managerRule = await deleteManagerRuleRequest(projectId, modelId, managerRuleId)

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const modifyManagerRule = async (
  projectId: string,
  modelId: string,
  managerRuleId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<OnStockManagerRule | undefined> => {
  // TODO: Add loading later

  try {
    const managerRule = await modifyManagerRuleRequest(
      projectId,
      modelId,
      managerRuleId,
      name,
      sectionTypeId,
      sectionParameters,
      materialParameter
    )

    return managerRule
  } catch (error: any) {
    console.error({ error })
    // TODO: Add error later
  }
}

export const getMaterials = async (): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getMaterialsQuery(),
  })

  return response.data.materialBanks[0].materials
}

export const getLatestSectionBank = async (): Promise<SectionBankData> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getLatestSectionBankQuery(),
  })

  return response.data.sectionBanks.nodes[0]
}

export const getSectionOrigins = async (bankName: string): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getSectionOriginsQuery(bankName),
  })

  return response.data.sectionOrigins
}

export const getSectionFamilies = async (
  bankName: string,
  originName: string
): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getSectionFamiliesQuery(bankName, originName),
  })

  return response.data.sectionFamilies
}

export const getSectionTypes = async (
  bankName: string,
  originName: string,
  familyName: string
): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getSectionTypesQuery(bankName, originName, familyName),
  })

  return response.data.sectionTypes
}

export const getSections = async (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string
): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()

  let responseData: SectionBankData[] = []
  let hasNextPage = true
  let endCursor = null

  while (hasNextPage) {
    const response = await client.query({
      query: getSectionsQuery(bankName, originName, familyName, typeName, endCursor),
    })

    const { nodes, pageInfo } = response.data.sections

    responseData = [...responseData, ...nodes]
    hasNextPage = pageInfo.hasNextPage
    endCursor = pageInfo.endCursor
  }

  return responseData
}

export const getSectionParameters = async (
  bankName: string,
  originName: string,
  familyName: string,
  typeName: string
): Promise<SectionBankData[]> => {
  // TODO: Add loading later
  const client = await getApolloClient()
  const response = await client.query({
    query: getSectionParametersQuery(bankName, originName, familyName, typeName),
  })
  return response.data.sectionParameters
}

//////////////////////
///ENGINEER RULES/////
//////////////////////

export const createOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<OnStockEngineerRule | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await createOnStockEngineerRuleRequest(
      projectId,
      modelId,
      portionSmadsteelId,
      portionName,
      condition
    )
    if (result && onStockStore.onStockModel) {
      setOnStockModelEngineerRules([...onStockStore.onStockModel.engineerRules, result])
    }
    decreaseOnstockProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

export const updateOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  ruleId: number,
  portionSmadsteelId: string,
  portionName: string,
  condition: EngineerRuleCondition
): Promise<OnStockEngineerRule | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await updateOnStockEngineerRuleRequest(
      projectId,
      modelId,
      ruleId,
      portionSmadsteelId,
      portionName,
      condition
    )
    decreaseOnstockProgress()

    if (result && onStockStore.onStockModel) {
      const updatedEngineerRule = onStockStore.onStockModel.engineerRules.map((engineerRule) => {
        if (engineerRule.id === ruleId) return result
        return engineerRule
      })

      setOnStockModelEngineerRules(updatedEngineerRule)
    }

    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

export const deleteOnStockEngineerRule = async (
  projectId: number,
  modelId: number,
  ruleId: number
): Promise<boolean | undefined> => {
  increaseOnstockProgress()

  try {
    const result = await deleteOnStockEngineerRuleRequest(projectId, modelId, ruleId)
    decreaseOnstockProgress()

    if (result && onStockStore.onStockModel) {
      const updatedRules = onStockStore.onStockModel.engineerRules.filter(
        (rule) => rule.id !== ruleId
      )
      setOnStockModelEngineerRules(updatedRules)
    }
    return result
  } catch (error: any) {
    console.error({ error })
    setOnStockError((error as Error).message)
  }

  decreaseOnstockProgress()
}

///////////////////
///OTHERS/////////
//////////////////

export function getProjectStatus(models: OnStockModel[]): string {
  const notAllModelsFinalized = models.some(
    (model) => model.status !== OnStockModelStatus.Finalized
  )
  return notAllModelsFinalized || !models.length ? 'In progress' : 'Finalized'
}

export const getOnStockUserRoleByEmail = (userEmail: string): string => {
  const user = onStockStore.onStockUsers.find((user) => user.email === userEmail)
  if (!user) {
    console.warn('OnStock user not found!')
    return ''
  }
  const role = user.roles.find((role) => role !== OnStockPermission.Admin)
  if (!role) return ''
  return role
}

export const getEngineerRuleConditionText = (condition: EngineerRuleCondition): string => {
  switch (condition) {
    case EngineerRuleCondition.MaterialAndSectionChangeAllowed:
      return 'Both section and material can change'

    case EngineerRuleCondition.NoChangeAllowed:
      return 'Neither the section nor the material can change'

    case EngineerRuleCondition.OnlyMaterialChangeAllowed:
      return 'Section remains the same, but material can change'
  }
}

export const getManagerRuleParameterText = (parameter: ParameterNames): string => {
  switch (parameter) {
    case ParameterNames.HEIGHT:
      return ParameterNamesMap[ParameterNames.HEIGHT]
    case ParameterNames.THICKNESS:
      return ParameterNamesMap[ParameterNames.THICKNESS]
    case ParameterNames.DIAMETER:
      return ParameterNamesMap[ParameterNames.DIAMETER]
    case ParameterNames.WIDTH:
      return ParameterNamesMap[ParameterNames.WIDTH]
    case ParameterNames.MATERIAL:
      return ParameterNamesMap[ParameterNames.MATERIAL]
  }
}

export const chainParameterNames = (
  managerRuleParameters: ManagerRuleParameter[],
  materialStep: number
): string => {
  const parameterNames: string[] = []
  managerRuleParameters.forEach((parameter) => {
    const convertedParameterName = getManagerRuleParameterText(
      parameter.sectionParameter.normalizedName as ParameterNames
    )
    parameterNames.push(convertedParameterName.toLowerCase())
  })
  if (materialStep)
    parameterNames.push(getManagerRuleParameterText(ParameterNames.MATERIAL).toLowerCase())

  return parameterNames
    .map((parameterName, index) =>
      index === 0 ? parameterName.charAt(0).toUpperCase() + parameterName.slice(1) : parameterName
    )
    .join(', ')
}

export const setDefaultManagerRuleDialogDataByRuleId = async (
  openOption: OpenOptions,
  managerRuleId?: number
): Promise<DefaultManagerRuleData> => {
  const defaultManagerRuleDialogData: DefaultManagerRuleData = {
    openOption: OpenOptions.Write,
    managerRuleId: '',
    dialogName: '',
    selectedSectionFamily: { text: '', id: '' },
    parameterSettings: [],
    sectionTableData: [],
  }

  if (!managerRuleId || openOption === OpenOptions.Write) return defaultManagerRuleDialogData

  const managerRuleData = onStockStore.onStockModel?.managerRules.find((rule) => {
    if (rule.id === managerRuleId) return rule
  })

  if (!managerRuleData) return defaultManagerRuleDialogData

  defaultManagerRuleDialogData.managerRuleId = managerRuleId.toString()
  defaultManagerRuleDialogData.dialogName = managerRuleData.name
  defaultManagerRuleDialogData.openOption = openOption

  const parameterSettings: ParameterData[] = []
  if (managerRuleData.sectionType !== null) {
    // specific section selected

    defaultManagerRuleDialogData.selectedSectionFamily = {
      text: managerRuleData.sectionType.name,
      id: '0' + '-' + managerRuleData.sectionType.id,
    }

    const sectionPath = managerRuleData.sectionType.path.split('.')
    const allParametersData: SectionBankData[] = await getSectionParameters(
      sectionPath[0],
      sectionPath[1],
      sectionPath[2],
      sectionPath[3]
    )

    managerRuleData.managerRuleParameters.forEach((parameter) => {
      parameterSettings.push({
        id: parameter.sectionParameter.id,
        name: parameter.sectionParameter.normalizedName,
        active: true,
        direction: ParameterDirectionsMap[parameter.direction],
        step: parameter.step,
      } as ParameterData)
    })
    allParametersData.forEach((parameter) => {
      const isParameterStoredBefore = parameterSettings.find(
        (param) => param.name === parameter.normalizedName
      )
      if (!isParameterStoredBefore)
        parameterSettings.push({
          id: parameter.id,
          name: parameter.normalizedName,
          active: false,
          direction: ParameterDirections.UpAndDown,
          step: ParameterSteps.Zero,
        } as ParameterData)
    })

    const sortedTableData = managerRuleData.sectionType.sections.sort(compareTwoSection)
    const sectionData: ManagerRuleTableData[] = []
    sortedTableData.forEach((material) => {
      sectionData.push({
        data: { ...material, id: '0' + '-' + material.id },
        checkedByUser: false,
        readOnlyChecked: false,
      })
    })
    defaultManagerRuleDialogData.sectionTableData = sectionData
  } else {
    // all section option selected (section type is null)

    defaultManagerRuleDialogData.selectedSectionFamily = {
      text: 'All',
      id: '0-all',
    }
    defaultManagerRuleDialogData.sectionTableData = []
  }

  // add material field as last item
  parameterSettings.push({
    id: 0,
    name: ParameterNames.MATERIAL,
    active: !!managerRuleData.materialStep,
    direction: ParameterDirectionsMap[managerRuleData.materialDirection],
    step: managerRuleData.materialStep,
  } as ParameterData)
  defaultManagerRuleDialogData.parameterSettings = parameterSettings

  return defaultManagerRuleDialogData
}

const compareTwoSection = (
  firstSectionItem: SectionBankData,
  secondSectionItem: SectionBankData
): number => {
  const firstData = firstSectionItem.name
    .trim()
    .split('x')
    .map((str) => Number(str))
  const secondData = secondSectionItem.name
    .trim()
    .split('x')
    .map((str) => Number(str))

  if (firstData[0] !== secondData[0]) {
    return firstData[0] - secondData[0]
  }
  if (firstData[1] !== secondData[1]) {
    return firstData[1] - secondData[1]
  }
  return firstData[2] - secondData[2]
}

const deleteObjectMatchesBasedOnSummaryItemHandler = async (id: number): Promise<void> => {
  if (!onStockStore.warehouseOptimization || !onStockStore.project || !onStockStore.onStockModel) {
    console.error(
      'There is no current warehouse optimization to deleting matches based on summary item'
    )
    return
  }

  await deleteObjectMatchesBasedOnSummaryItem(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    onStockStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}

const deleteWarehouseObjectsByMatchedWarehouseObjectHandler = async (id: number): Promise<void> => {
  if (!onStockStore.warehouseOptimization || !onStockStore.project || !onStockStore.onStockModel) {
    console.error(
      'There is no current correspondence to deleting matches by matched warehouse object'
    )
    return
  }

  await deleteWarehouseObjectsByMatchedWarehouseObject(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    onStockStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}

const deleteObjectMatchHandler = async (id: number): Promise<void> => {
  if (!onStockStore.warehouseOptimization || !onStockStore.project || !onStockStore.onStockModel) {
    console.error('There is no current correspondence to deleting match')
    return
  }

  await deleteObjectMatch(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    onStockStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}

export const buildWarehouseOptimizationTree = (
  warehouseOptimization: OnStockWarehouseOptimization,
  modelFile: Model,
  sectionMap: Map<string, WarehouseOptimizationTreeNode>,
  filter: SummaryType,
  isCompare: boolean,
  modelState?: ModelStateNumber,
  portionId?: string | null,
  hidden = false
): Map<string, WarehouseOptimizationTreeNode> => {
  const _sectionMap = sectionMap

  for (const warehouseSummaryItem of warehouseOptimization.warehouseSummaryItems) {
    if (
      (filter === SummaryType.Section && warehouseSummaryItem.type === SummaryType.Section) ||
      (filter === SummaryType.Material && warehouseSummaryItem.type === SummaryType.Material)
    ) {
      const warehouseSummaryItemChild: WarehouseOptimizationTreeNode = {
        id: warehouseSummaryItem.id.toString() ?? 'Other',
        name: warehouseSummaryItem.name ?? 'N/A',
        children: [],
        quantity: warehouseSummaryItem.quantity,
        value: warehouseSummaryItem.length,
        warehouseSummaryItemBlocks: warehouseSummaryItem.warehouseSummaryItemBlocks,
        compare: isCompare,
        modelState,
        objectMatchId: warehouseSummaryItem.id,
        deleteMatchHandler: deleteObjectMatchesBasedOnSummaryItemHandler,
      }

      const relevantWarehouseSummaryItem = _sectionMap.get(warehouseSummaryItem.name)

      if (!relevantWarehouseSummaryItem) {
        _sectionMap.set(warehouseSummaryItem.name, warehouseSummaryItemChild)
      } else {
        relevantWarehouseSummaryItem.valueToCompare = warehouseSummaryItem.length
        relevantWarehouseSummaryItem.quantityToCompare = warehouseSummaryItem.quantity

        relevantWarehouseSummaryItem.warehouseSummaryItemBlocksToCompare =
          warehouseSummaryItem.warehouseSummaryItemBlocks

        _sectionMap.set(warehouseSummaryItem.name, relevantWarehouseSummaryItem)
      }
    }
  }

  const objectMatches = portionId
    ? warehouseOptimization.warehouseOptimizationPortions.find(
        (portion) => portion.portionSmadsteelId == portionId
      )?.warehouseObjectMatches
    : warehouseOptimization.warehouseObjectMatches

  if (!objectMatches) return _sectionMap

  for (const objectMatch of objectMatches) {
    const relevantWarehouseSummaryItem =
      filter === SummaryType.Section
        ? warehouseOptimization.warehouseSummaryItems.find(
            (item) => item.name == objectMatch.warehouseObject.sectionName
          )
        : warehouseOptimization.warehouseSummaryItems.find(
            (item) => item.name == objectMatch.warehouseObject.materialName
          )

    if (!relevantWarehouseSummaryItem) {
      console.warn('relevantWarehouseSummaryItem not found')
      return _sectionMap
    }

    const relevantWarehouseItemNode = _sectionMap.get(relevantWarehouseSummaryItem.name)

    const relevantSmadsteelObject = Get<StructuralMember>(
      modelFile,
      objectMatch.smadsteelId,
      StructuralMember
    )

    const objectMatchChild: WarehouseOptimizationTreeNode = {
      id: relevantSmadsteelObject?.id ?? 'Other',
      name: relevantSmadsteelObject?.name ?? 'N/A',
      children: undefined,
      value: (relevantSmadsteelObject?.geometry?.length || 0) * 1000,
      compare: isCompare,
      modelState,
      hidden,
      objectMatchId: objectMatch.id,
      deleteMatchHandler: deleteObjectMatchHandler,
    }

    const barcodeChild: WarehouseOptimizationTreeNode = {
      id: objectMatch.warehouseObject.barcode.toString() ?? 'Other',
      name: objectMatch.warehouseObject.barcode.toString() ?? 'N/A',
      value: objectMatch.warehouseObject.length,
      open: false,
      modelState,
      children: [objectMatchChild],
      compare: isCompare,
      objectMatchId: objectMatch.warehouseObject.id,
      deleteMatchHandler: deleteWarehouseObjectsByMatchedWarehouseObjectHandler,
    }

    if (relevantWarehouseItemNode) {
      const relevantBarcodeNode = relevantWarehouseItemNode.children?.find(
        (item) => item.id.toString() == objectMatch.warehouseObject.barcode.toString()
      )

      if (relevantBarcodeNode) {
        relevantBarcodeNode.children?.push(objectMatchChild)
      } else {
        relevantWarehouseItemNode.children?.push(barcodeChild)
      }
    }
  }

  return _sectionMap
}

export const buildRemainingItemsTree = (
  remainingMembers: StructuralMember[],
  filter: SummaryType
): Map<string, WarehouseOptimizationTreeNode> => {
  const remainingOptMap = new Map<string, WarehouseOptimizationTreeNode>()
  const isFilterSection = filter === SummaryType.Section

  for (const member of remainingMembers) {
    const itemId = isFilterSection ? member.section?.id : member.material?.id // todo name for this
    const itemName = isFilterSection ? member.section?.name : member.material?.name

    if (!itemId || !itemName) return new Map<string, WarehouseOptimizationTreeNode>()

    const itemData = {
      id: itemId,
      name: itemName,
      value: 0,
      hideProgress: true,
    } as WarehouseOptimizationTreeNode

    const memberData = {
      id: member.id,
      name: member.name,
      value: 0,
      hideProgress: true,
    } as WarehouseOptimizationTreeNode

    const section = remainingOptMap.get(itemName)

    if (!section) {
      itemData.children = [memberData]
      remainingOptMap.set(itemName, itemData)
    } else {
      const children = section.children || []

      section.children = [...children, memberData]
      remainingOptMap.set(itemName, section)
    }
  }

  return remainingOptMap
}

export const setFetchedWarehouseOptimization = async (
  isCompare: boolean,
  historyId: string
): Promise<void> => {
  if (onStockStore.onStockModel && onStockStore.project) {
    if (isCompare) return

    const relevantWarehouseOptimization = await getWarehouseOptimization(historyId)

    setWarehouseOptimization(relevantWarehouseOptimization || null)
  }
}

export const warehouseOptimizationItems = (
  warehouseOptimization: OnStockWarehouseOptimization | null,
  warehouseOptimizationSecondary: OnStockWarehouseOptimization | null,
  modelFile: Model | null,
  modelFileSecondary: Model | null,
  filter: SummaryType,
  isCompare: boolean,
  portionId?: string | null
): WarehouseOptimizationTreeNode[] => {
  let sectionMap = new Map<string, WarehouseOptimizationTreeNode>()

  if (warehouseOptimization && modelFile) {
    sectionMap = buildWarehouseOptimizationTree(
      warehouseOptimization,
      modelFile,
      sectionMap,
      filter,
      isCompare,
      isCompare ? ModelStateNumber.primary : undefined,
      portionId,
      getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
    )
  }

  if (isCompare && warehouseOptimizationSecondary && modelFileSecondary) {
    sectionMap = buildWarehouseOptimizationTree(
      warehouseOptimizationSecondary,
      modelFileSecondary,
      sectionMap,
      filter,
      isCompare,
      ModelStateNumber.secondary,
      portionId,
      getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
    )
  }

  const result = [] as WarehouseOptimizationTreeNode[]
  sectionMap.forEach((value) => {
    result.push(value)
  })

  return result
}

export async function deleteObjectMatchesBasedOnSummaryItem(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  warehouseSummaryItem: string
): Promise<boolean> {
  increaseOnstockProgress()
  try {
    const result = await deleteObjectMatchesBasedOnSummaryItemRequest(
      projectId,
      modelId,
      correspondenceId,
      warehouseSummaryItem
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseOnstockProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseOnstockProgress()
    setOnStockError((error as Error).message)
  }
  return false
}
export async function deleteWarehouseObjectsByMatchedWarehouseObject(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  matchedWarehouseObjectId: string
): Promise<boolean> {
  increaseOnstockProgress()
  try {
    const result = await deleteWarehouseObjectsByMatchedWarehouseObjectRequest(
      projectId,
      modelId,
      correspondenceId,
      matchedWarehouseObjectId
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseOnstockProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseOnstockProgress()
    setOnStockError((error as Error).message)
  }
  return false
}
export async function deleteObjectMatch(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  objectMatchId: string
): Promise<boolean> {
  increaseOnstockProgress()
  try {
    const result = await deleteObjectMatchRequest(
      projectId,
      modelId,
      correspondenceId,
      objectMatchId
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseOnstockProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseOnstockProgress()
    setOnStockError((error as Error).message)
  }
  return false
}

export const normalizeName = (name: string): string => {
  return name
    .replaceAll(' ', '')
    .replaceAll('.', ',')
    .replaceAll('*', 'x')
    .replaceAll('-', '')
    .toUpperCase()
}

export const isSectionNameEqual = (name1: string, name2: string): boolean => {
  const normalizedName1 = normalizeName(name1)
  const normalizedName2 = normalizeName(name2)

  return (
    normalizedName1.includes(normalizedName2) ||
    normalizedName2.includes(normalizedName1) ||
    [...normalizedName1].sort().join('') === [...normalizedName2].sort().join('')
  )
}

export const warehouseOptColor = (): string => {
  return '#16A1F0'
}

export const supplyOptColor = (): string => {
  return '#DCB21C'
}

export const setSelectionAndIsolation = (...ids: string[]): void => {
  addItemsToSelection(...ids)
  if (
    modelViewerStore.unselectedMembersToTransparentOption &&
    !modelViewerStore.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}

export const removeSelectionAndIsolation = (...ids: string[]): void => {
  removeItemsFromSelection(...ids)
  if (
    modelViewerStore.unselectedMembersToTransparentOption &&
    !modelViewerStore.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}
