import { OnStockModel, OnStockModelStatus } from '@/modules/graphql/graphql.types'
import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import { CreateOnStockModelDto, UpdateOnStockModelDto } from '../types'

export const updateOnStockModelRequest = async (
  projectId: string,
  modelId: string,
  status: OnStockModelStatus,
  checksum?: string
): Promise<OnStockModel | undefined> => {
  const dto = new UpdateOnStockModelDto(status, checksum)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}`,
    'PUT',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: OnStockModel = <OnStockModel>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createOnStockModelRequest = async (
  projectId: string,
  steelspaceId: string,
  checksum: string
): Promise<OnStockModel | undefined> => {
  const dto = new CreateOnStockModelDto(steelspaceId, checksum)

  const response = await doRequest(
    `users/me/projects/${projectId}/models`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result: OnStockModel = <OnStockModel>responseJson.payload

    return result
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteOnStockModelRequest = async (
  projectId: string,
  modelId: string
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
