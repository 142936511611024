import { ManagerRule } from '@/modules/graphql/graphql.types'
import { doRequest } from '../../../services/requests/Base'
import { SteelspaceResponse } from '../../../types'
import {
  MaterialManagerRuleParameter,
  SectionManagerRuleParameter,
} from '../types/OnStockManagerRuleInterfaces'
import {
  OnStockPreviewManagerRuleMaterialModel,
  OnStockPreviewManagerRuleSectionModel,
} from '../types/OnStockManagerRules.model'
import { CreateManagerRuleDto } from '../types/dtos/CreateManagerRuleDto'
import { CreatePreviewManagerRuleMaterialDto } from '../types/dtos/CreatePreviewManagerRuleMaterialDto'
import { CreatePreviewManagerRuleSectionDto } from '../types/dtos/CreatePreviewManagerRuleSectionDto'

export const createPreviewManagerRuleSectionRequest = async (
  projectId: string,
  modelId: string,
  sectionId: string,
  sectionParameters: Array<SectionManagerRuleParameter>
): Promise<OnStockPreviewManagerRuleSectionModel[] | undefined> => {
  const dto = new CreatePreviewManagerRuleSectionDto(sectionParameters)

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/manager/preview/sections/${sectionId}`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<OnStockPreviewManagerRuleSectionModel[]>(
      (responseJson.payload as unknown)
    )) as OnStockPreviewManagerRuleSectionModel[]
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createPreviewManagerRuleMaterialRequest = async (
  projectId: string,
  modelId: string,
  materialId: string,
  materialParameter: MaterialManagerRuleParameter
): Promise<OnStockPreviewManagerRuleMaterialModel[] | undefined> => {
  const dto = new CreatePreviewManagerRuleMaterialDto(
    materialParameter.direction,
    materialParameter.step
  )

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/manager/preview/materials/${materialId}`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return (<OnStockPreviewManagerRuleMaterialModel[]>(
      (responseJson.payload as unknown)
    )) as OnStockPreviewManagerRuleMaterialModel[]
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const createManagerRuleRequest = async (
  projectId: string,
  modelId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<ManagerRule | undefined> => {
  const dto = new CreateManagerRuleDto(
    name,
    sectionTypeId,
    sectionParameters,
    materialParameter.direction,
    materialParameter.step
  )

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/manager`,
    'POST',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 201) {
    return (<ManagerRule>(responseJson.payload as unknown)) as ManagerRule
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteManagerRuleRequest = async (
  projectId: string,
  modelId: string,
  managerRuleId: string
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/manager/${managerRuleId}`,
    'DELETE',
    undefined,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const modifyManagerRuleRequest = async (
  projectId: string,
  modelId: string,
  managerRuleId: string,
  name: string,
  sectionTypeId: number,
  sectionParameters: Array<SectionManagerRuleParameter>,
  materialParameter: MaterialManagerRuleParameter
): Promise<ManagerRule | undefined> => {
  const dto = new CreateManagerRuleDto(
    name,
    sectionTypeId,
    sectionParameters,
    materialParameter.direction,
    materialParameter.step
  )

  const response = await doRequest(
    `users/me/projects/${projectId}/models/${modelId}/rules/manager/${managerRuleId}`,
    'PUT',
    dto,
    process.env.VUE_APP_ONSTOCK_API_BASEURL,
    true,
    true
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return (<ManagerRule>(responseJson.payload as unknown)) as ManagerRule
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}
